<template>
  <div>
    <v-card>
      <v-card-text
        v-if="!showScheduling"
        class="font-weight-bold"
      >
        You do not have permissions to schedule and perform broadcasts.
        <v-btn
          color="success"
          class="ml-2"
          @click="$router.push({ name: 'CampaignDashboard' })"
        >
          Ok
        </v-btn>
      </v-card-text>
      <v-card-text
        v-else-if="requiredToScheduleMailingId"
        class="font-weight-bold"
      >
        You must first schedule a previous mailing from the campaign.
        <v-btn
          color="success"
          class="ml-2"
          @click="goToRequiredToScheduleMailing()"
        >
          Let's go
          <v-icon right>
            mdi-menu-right-outline
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text
        v-else-if="!isTAComplete"
        class="font-weight-bold"
      >
        You must first complete your Target Audience.
        <v-btn
          color="success"
          class="ml-2"
          @click="goToEditTA()"
        >
          Let's go
          <v-icon right>
            mdi-menu-right-outline
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text
        v-else
        class="pa-2"
      >
        <p
          class="font-italic"
        >
          All times are displayed in local time. Broadcast mailings will be sent utilizing send time optimization based on the Target Audience size.
          <!-- Based on Target Audience size, your mailing may need additional days to spread across. -->
          <!-- <span v-if="getIsResend">
            Resends that are not "Full File" may send across shorter durations based on computed audience size from results.
          </span> -->
        </p>
        <v-row class="justify-space-between mb-0">
          <v-col
            cols="12"
            lg="6"
            class="d-flex justify-center"
          >
            <v-row class="justify-center">
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <h2>Schedule Date</h2>
              </v-col>
              <!-- <v-col
                v-if="getIsResend && showPrevSchedulingMessage"
                cols="12"
                class="d-flex justify-center pa-1"
              >
                <span>The previous Mailing on this campaign was scheduled for <strong>{{ prevSendSTO.sendStartLocal | displayDateTimeFormat }}</strong>,
                  <br>
                  which sends to a max audience size of
                  <v-chip
                    class="mx-1"
                    :color="$_dashMixin_countColor(prevSendSTO.audienceCount)"
                    dark
                  >
                    {{ prevSendSTO.audienceCount | commaNumber }}
                  </v-chip>
                  and sends across <strong>{{ prevSendSTO.totalIterableHours / 24 }}</strong> day(s)
                  <br>
                  Scheduling will require the previous Mailing to finish sending and at least an additional <strong>{{ resendBufferHours / 24 }}</strong> days
                </span>
              </v-col> -->
              <v-col
                v-for="(split, index) in splitMixin_splits"
                :key="index"
                cols="12"
                class="d-flex justify-center align-center pa-1"
              >
                <span
                  v-if="currentCalendarItem && currentCalendarItem.targetAudience"
                >
                  {{ splitMixin_splits.length === 1 ? '' : 'Split' }}
                  <v-btn
                    v-if="splitMixin_splits.length > 1"
                    icon
                    :color="$_splitMixin_splitColors[index]"
                    @click="getSplitHtmlDialog(split.id, $store.getters['mailing/getMailingItemId']())"
                  >
                    <span
                      class="title"
                    >
                      {{ $_splitMixin_indexToChar(index) }}
                    </span>
                  </v-btn>
                  {{ splitMixin_splits.length === 1 ? 'This mailing' : `(${split.distribution}%)` }} will broadcast over
                  <!-- <v-chip
                    class="mx-1"
                    :color="$_dashMixin_countColor(Math.ceil(split.distribution * currentCalendarItem.targetAudience.trueCount / 100))"
                    dark
                  >
                    {{ Math.ceil(split.distribution * currentCalendarItem.targetAudience.trueCount / 100) | commaNumber }}
                  </v-chip>
                  which will send across  --><strong>{{ $_dashMixin_countSendDaysShortLabel(Math.ceil(split.distribution * currentCalendarItem.targetAudience.trueCount / 100)).value }}</strong> {{ $_dashMixin_countSendDaysShortLabel(Math.ceil(split.distribution * currentCalendarItem.targetAudience.trueCount / 100)).label }}
                </span>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="6"
                xl="5"
                class="d-flex justify-center"
              >
                <v-date-picker
                  v-model="scheduleDateDash"
                  class="mx-2"
                  elevation="4"
                  header-color="primary"
                  :color="selectColor"
                  :min="minDate.local().format()"
                  :max="maxDate.local().format()"
                  show-current
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="6"
                xl="5"
                class="d-flex justify-center"
              >
                <v-time-picker
                  v-model="scheduleTime"
                  class="mx-2"
                  elevation="4"
                  header-color="primary"
                  :color="selectColor"
                  ampm-in-title
                  scrollable
                  format="ampm"
                  :min="minTime"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="$_splitMixin_isSplitTest"
            cols="12"
            lg="6"
            class="d-flex justify-center"
          >
            <v-row class="justify-center">
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <h2>Winning Schedule Date</h2>
              </v-col>
              <!-- <v-col
                cols="12"
                class="d-flex justify-center align-center pa-1"
              >
                <span>
                  Winner will require the Splits to finish sending and at least an additional <strong>{{ winningSendBufferHours }}</strong> hours
                </span>
              </v-col> -->
              <v-col
                cols="12"
                class="d-flex justify-center align-center pa-1"
              >
                <span
                  v-if="currentCalendarItem && currentCalendarItem.targetAudience"
                >
                  Winner ({{ $store.getters['mailing/getWinningDistribution']() }}%) will broadcast over
                  <!-- <v-chip
                    class="mx-1"
                    :color="$_dashMixin_countColor(Math.ceil($store.getters['mailing/getWinningDistribution']() * currentCalendarItem.targetAudience.trueCount / 100))"
                    dark
                  >
                    {{ Math.ceil($store.getters['mailing/getWinningDistribution']() * currentCalendarItem.targetAudience.trueCount / 100) | commaNumber }}
                  </v-chip>
                  which will send across --><strong>{{ $_dashMixin_countSendDaysShortLabel(Math.ceil($store.getters['mailing/getWinningDistribution']() * currentCalendarItem.targetAudience.trueCount / 100)).value }}</strong> {{ $_dashMixin_countSendDaysShortLabel(Math.ceil($store.getters['mailing/getWinningDistribution']() * currentCalendarItem.targetAudience.trueCount / 100)).label }}
                </span>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="6"
                xl="5"
                class="d-flex justify-center"
              >
                <v-date-picker
                  v-model="winningScheduleDateDash"
                  class="mx-2"
                  elevation="4"
                  :header-color="winningColor"
                  :color="selectColor"
                  :min="winningMinDate.format()"
                  :max="maxDate.local().format()"
                  :show-current="false"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="6"
                xl="5"
                class="d-flex justify-center"
              >
                <v-time-picker
                  v-model="winningScheduleTime"
                  class="mx-2"
                  elevation="4"
                  :header-color="winningColor"
                  :color="selectColor"
                  ampm-in-title
                  scrollable
                  format="ampm"
                  :min="winningMinTime"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="getIsResend"
            cols="12"
            sm="9"
            md="6"
            class="d-flex"
          >
            <v-select
              v-model="resendType"
              :items="resendTypeSelect"
              dense
              color="primary"
              item-color="primary"
              label="Resend Type"
              :error-messages="resendTypeErrors"
              class="px-2 pt-3"
              @change="$v.resendType.$touch(); setMailingValue('resendType')"
            />
            <v-select
              v-model="globalResends"
              :items="globalResendsSelect"
              :loading="globalResendsLoading"
              :disabled="resendType === $_resendType_FullFile"
              dense
              multiple
              color="primary"
              item-color="primary"
              label="Global Resend Audience"
              :error-messages="globalResendsErrors"
              class="px-2 pt-4"
              @change="$v.globalResends.$touch(); setMailingValue('globalResends')"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.text }}</span>
                <span v-if="index === 1">, {{ item.text }}</span>
                <span
                  v-if="index === 2"
                  class="grey--text text-caption"
                > (+{{ globalResends.length - 2 }} others)</span>
              </template>
            </v-select>
          </v-col>
          <v-col
            v-if="$_splitMixin_isSplitTest"
            cols="12"
          >
            <p
              class="font-italic"
            >
              To preserve the integrity of the Split Test results, the Test Contacts will <strong>not</strong> receive a copy of the Split Tests, but will receieve a copy of the Winner.
            </p>
          </v-col>
        </v-row>
        <v-divider />
        <v-expansion-panels multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Campaign Calendar
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="auto">
                  <v-btn
                    class="mr-4"
                    :color="color"
                    @click="setToday"
                  >
                    Today
                  </v-btn>
                  <v-btn
                    fab
                    small
                    class="mx-1"
                    :color="color"
                    @click="prev"
                  >
                    <v-icon small>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    small
                    class="mx-1"
                    :color="color"
                    @click="next"
                  >
                    <v-icon small>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                  <v-toolbar-title class="text-h4 mx-4">
                    {{ calendarTitle }}
                  </v-toolbar-title>
                </v-col>
                <v-spacer />
                <v-col
                  cols="auto"
                >
                  <v-btn-toggle
                    v-model="mailingStatusFilter"
                    dense
                    mandatory
                    multiple
                  >
                    <v-btn
                      v-for="n in mailingStatues"
                      :key="n"
                      :value="n"
                      :color="$_dashMixin_getMailingIconColor({ status: n })"
                      text
                    >
                      <v-icon>
                        {{ $_dashMixin_getMailingIcon({ discriminator: 'MailingItem', status: n }) }}
                      </v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="auto">
                  <v-btn-toggle
                    v-model="calendar.type"
                    dense
                    mandatory
                    :color="color"
                    class="pl-1"
                  >
                    <v-btn value="day">
                      Day
                    </v-btn>
                    <v-btn value="week">
                      Week
                    </v-btn>
                    <v-btn value="month">
                      Month
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <!--  class="fill-height" -->
                <v-col>
                  <v-sheet min-height="600">
                    <v-skeleton-loader
                      v-if="isLoading()"
                      type="date-picker"
                    />
                    <v-calendar
                      v-show="!isLoading()"
                      ref="calendar"
                      v-model="calendar.focus"
                      :color="color"
                      :events="mailingsToEvents"
                      :event-color="getEventColor"
                      :event-margin-bottom="3"
                      :now="calendar.today"
                      :type="calendar.type"
                      @click:event="showEvent"
                      @click:more="viewDay"
                      @click:date="viewDay"
                      @mouseenter:event="mouseEnterEvent"
                      @mouseleave:event="mouseLeaveEvent"
                      @change="updateRange"
                    />
                    <v-menu
                      v-model="calendar.selectedOpen"
                      :close-on-content-click="false"
                      :close-on-click="false"
                      :activator="calendar.selectedElement"
                      offset-x
                    >
                      <v-card
                        v-if="calendar.selectedEvent && calendar.selectedEvent.targetAudience && calendar.selectedEvent.campaign && calendar.selectedEvent.mailing && calendar.selectedEvent.mailing.splits"
                        color="grey lighten-4"
                        min-width="400px"
                        flat
                      >
                        <v-toolbar
                          :color="calendar.selectedEvent.color"
                          dark
                        >
                          <v-toolbar-title v-html="calendar.selectedEvent.name" />
                        </v-toolbar>
                        <v-card-text>
                          <v-row>
                            <v-col
                              cols="12"
                            >
                              <span
                                v-if="calendar.selectedEvent.mailing.splits && calendar.selectedEvent.mailing.splits.length === 1"
                                class="font-weight-bold"
                              >
                                {{ calendar.selectedEvent.mailing.splits[0].subject }}
                              </span>
                              <span v-else>
                                <v-tooltip
                                  v-for="(split, i) in calendar.selectedEvent.mailing.splits"
                                  :key="i"
                                  top
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-progress-linear
                                      :style="`width: ${split.distribution}%`"
                                      striped
                                      height="20"
                                      value="100"
                                      :color="$_splitMixin_splitColors[i]"
                                      v-on="on"
                                    >
                                      <template v-slot="{ value }">
                                        <strong>{{ split.distribution }}%</strong>
                                      </template>
                                    </v-progress-linear>
                                  </template>
                                  <span>{{ split.subject || `Subject ${$_splitMixin_splitLetters[i]}` }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-progress-linear
                                      :style="`width: ${calendar.selectedEvent.mailing.winningDistribution}%`"
                                      striped
                                      height="20"
                                      buffer-value="100"
                                      :color="$_splitMixin_winningColor"
                                      v-on="on"
                                    >
                                      <template v-slot="{ value }">
                                        <strong>{{ calendar.selectedEvent.mailing.winningDistribution }}%</strong>
                                      </template>
                                    </v-progress-linear>
                                  </template>
                                  <span>{{ $_splitMixin_winningCriteriaSelect.find(x => x.value === calendar.selectedEvent.mailing.winningCriteria) ? $_splitMixin_winningCriteriaSelect.find(x => x.value === calendar.selectedEvent.mailing.winningCriteria).text : 'TBD' }}</span>
                                </v-tooltip>
                              </span>
                              <div v-if="calendar.selectedEvent.mailing.modifiedOnLocal">
                                Modified: {{ calendar.selectedEvent.mailing.modifiedOnLocal | displayDateTimeFormat }} by {{ calendar.selectedEvent.mailing.modifiedBy }}
                              </div>
                              <div v-if="calendar.selectedEvent.mailing.winningCriteria">
                                Winning Criteria: {{ `${$_splitMixin_winningCriteriaSelect.find(x => x.value === calendar.selectedEvent.mailing.winningCriteria).text} (${calendar.selectedEvent.mailing.winningDistribution}%)` }}
                              </div>
                              <div v-if="calendar.selectedEvent.mailing.resendType">
                                Resend Type: {{ $_splitMixin_resendTypeSelect.find(x => x.value === calendar.selectedEvent.mailing.resendType).text }}
                              </div>
                              <div class="pb-2">
                                <v-btn
                                  v-for="(split, i) in calendar.selectedEvent.mailing.splits"
                                  :key="split.id"
                                  icon
                                  :color="$_splitMixin_splitColors[i]"
                                  @click="getSplitHtmlDialog(split.id, calendar.selectedEvent.mailing.id)"
                                >
                                  <span
                                    class="title"
                                  >
                                    {{ $_splitMixin_indexToChar(i) }}
                                  </span>
                                </v-btn>
                                <v-chip
                                  v-if="calendar.selectedEvent.mailing.scheduleDate && calendar.selectedEvent.mailing.isScheduledStatus"
                                  :color="$_dashMixin_scheduleDateColor($_dashMixin_dateToLocal(calendar.selectedEvent.mailing.scheduleDate), calendar.selectedEvent.mailing, calendar.selectedEvent.campaign)"
                                  class="ml-4"
                                  dark
                                >
                                  {{ $_dashMixin_dateToLocal(calendar.selectedEvent.mailing.scheduleDate) | displayDateTimeFormat }}
                                </v-chip>
                                {{ calendar.selectedEvent.mailing.winningDistribution > 0 && calendar.selectedEvent.mailing.winningScheduleDate && calendar.selectedEvent.mailing.isScheduledStatus ? ' / ' : '' }}
                                <v-chip
                                  v-if="calendar.selectedEvent.mailing.winningDistribution > 0 && calendar.selectedEvent.mailing.winningScheduleDate && calendar.selectedEvent.mailing.isScheduledStatus"
                                  :color="$_dashMixin_scheduleDateColorWinner($_dashMixin_dateToLocal(calendar.selectedEvent.mailing.winningScheduleDate), calendar.selectedEvent.mailing, calendar.selectedEvent.campaign)"
                                  dark
                                >
                                  {{ $_dashMixin_dateToLocal(calendar.selectedEvent.mailing.winningScheduleDate) | displayDateTimeFormat }}
                                </v-chip>
                              </div>
                              <v-divider />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="6">
                              <div v-if="calendar.selectedEvent.targetAudience">
                                <div
                                  v-if="calendar.selectedEvent.targetAudience.status"
                                  class="pb-1"
                                >
                                  <span class="font-weight-bold">Target Audience: </span>
                                  <v-tooltip
                                    top
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        class="mr-2"
                                        :color="$_dashMixin_taStatusColor(calendar.selectedEvent.targetAudience.status)"
                                        v-on="on"
                                      >
                                        {{ $_dashMixin_getTAIcon(calendar.selectedEvent.targetAudience.status) }}
                                      </v-icon>
                                    </template>
                                    <span>{{ calendar.selectedEvent.targetAudience.statusDisplay || 'Not selected' }}</span>
                                  </v-tooltip>
                                  <span v-if="!calendar.selectedEvent.campaign.readOnlyActions && showTAActions">
                                    <v-tooltip
                                      top
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          class="mr-2"
                                          :color="calendar.selectedEvent.targetAudience.status === $_taStatus_Draft ? 'success' : 'info'"
                                          @click="$_dashMixin_nowwEditLink(calendar.selectedEvent.targetAudience)"
                                          v-on="on"
                                        >
                                          {{ $_dashMixin_getTAIcon(calendar.selectedEvent.targetAudience.status) }}
                                        </v-icon>
                                      </template>
                                      <span>{{ calendar.selectedEvent.targetAudience.status === $_taStatus_Draft ? 'Edit' : 'View' }} Target Audience</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          color="info"
                                          @click="$_dashMixin_nowwCopyLink(calendar.selectedEvent.targetAudience)"
                                          v-on="on"
                                        >
                                          mdi-content-copy
                                        </v-icon>
                                      </template>
                                      <span>Copy Target Audience</span>
                                    </v-tooltip>
                                  </span>
                                </div>
                                <div v-if="calendar.selectedEvent.targetAudience.jobNumber">
                                  {{ calendar.selectedEvent.targetAudience.jobNumber }}
                                </div>
                                <div v-if="calendar.selectedEvent.targetAudience.poNumber">
                                  {{ calendar.selectedEvent.targetAudience.poNumber }}
                                </div>
                                <div v-if="calendar.selectedEvent.targetAudience.contactName">
                                  Contact: {{ calendar.selectedEvent.targetAudience.contactName }}
                                </div>
                                <div v-if="calendar.selectedEvent.targetAudience.orderDateLocal">
                                  Order Date: {{ calendar.selectedEvent.targetAudience.orderDateLocal | displayDateFormat }}
                                </div>
                                <div v-if="calendar.selectedEvent.targetAudience.fileType">
                                  <v-chip
                                    :color="$_dashMixin_fileTypeColor(calendar.selectedEvent.targetAudience.fileType)"
                                    :dark="$_dashMixin_fileTypeColorDark(calendar.selectedEvent.targetAudience.fileType)"
                                  >
                                    {{ calendar.selectedEvent.targetAudience.fileType }}
                                  </v-chip>
                                </div>
                                <div v-if="calendar.selectedEvent.targetAudience.count">
                                  Count:
                                  <v-tooltip
                                    v-if="!calendar.selectedEvent.targetAudience.nthRecordSubCount"
                                    top
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-chip
                                        :color="$_dashMixin_countColor(calendar.selectedEvent.targetAudience.count)"
                                        dark
                                        v-on="on"
                                      >
                                        {{ calendar.selectedEvent.targetAudience.count | commaNumber }}
                                      </v-chip>
                                    </template>
                                    <span>{{ $_dashMixin_countSendDaysLabel(calendar.selectedEvent.targetAudience.count) }}</span>
                                  </v-tooltip>
                                  <v-tooltip
                                    v-else
                                    top
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-chip
                                        :color="$_dashMixin_countColor(calendar.selectedEvent.targetAudience.nthRecordSubCount)"
                                        dark
                                        v-on="on"
                                      >
                                        {{ calendar.selectedEvent.targetAudience.nthRecordSubCount | commaNumber }}/{{ calendar.selectedEvent.targetAudience.count | commaNumber }}
                                      </v-chip>
                                    </template>
                                    <span>{{ calendar.selectedEvent.targetAudience.nthRecordSubCount | commaNumber }} Nth record of {{ calendar.selectedEvent.targetAudience.count | commaNumber }} count. {{ $_dashMixin_countSendDaysLabel(calendar.selectedEvent.targetAudience.nthRecordSubCount) }}</span>
                                  </v-tooltip>
                                </div>
                              </div>
                            </v-col>
                            <v-divider
                              vertical
                              class="mx-n1"
                            />
                            <v-col cols="6">
                              <div v-if="calendar.selectedEvent.campaign.campaignStatus">
                                <span class="font-weight-bold">Campaign: </span>
                                <v-chip
                                  class="mr-2"
                                  :color="$_dashMixin_campaignStatusColor(calendar.selectedEvent.campaign)"
                                  light
                                >
                                  {{ calendar.selectedEvent.campaign.campaignStatusDisplay }}
                                </v-chip>
                                <span v-if="!calendar.selectedEvent.campaign.readOnlyActions">
                                  <v-tooltip
                                    top
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        :disabled="!calendar.selectedEvent.campaign.canAddMailings"
                                        class="mr-2"
                                        color="success"
                                        @click="$_dashMixin_addMailing(calendar.selectedEvent.campaign)"
                                        v-on="on"
                                      >
                                        mdi-email-plus
                                      </v-icon>
                                    </template>
                                    <span>Add Mailing</span>
                                  </v-tooltip>
                                  <v-menu
                                    right
                                    x-offset
                                    origin="right"
                                    transition="slide-x-transition"
                                  >
                                    <template v-slot:activator="{ on: onMenu }">
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on: onTooltip }">
                                          <v-icon
                                            :disabled="calendar.selectedEvent.campaign.campaignStatus !== 1"
                                            class="mr-2"
                                            color="error"
                                            v-on="{ ...onMenu, ...onTooltip }"
                                          >
                                            mdi-close
                                          </v-icon>
                                        </template>
                                        <span>Cancel Campaign</span>
                                      </v-tooltip>
                                    </template>
                                    <v-card>
                                      <v-card-text class="pa-5">
                                        <span>Are you sure you want to cancel this campaign? No scheduled mailings will be sent.</span>
                                      </v-card-text>
                                      <v-divider />
                                      <v-card-actions>
                                        <v-spacer />
                                        <v-btn
                                          color="tertiary"
                                          :disabled="isLoading()"
                                        >
                                          Cancel
                                        </v-btn>
                                        <v-btn
                                          color="error"
                                          :loading="isLoading()"
                                          @click="calendar.selectedEvent.campaign = $_dashMixin_cancelCampaign(calendar.selectedEvent.campaign)"
                                        >
                                          Yes
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-menu>
                                  <v-tooltip
                                    top
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        class="mr-2"
                                        color="info"
                                        @click="copyCampaign(calendar.selectedEvent.campaign)"
                                        v-on="on"
                                      >
                                        mdi-content-copy
                                      </v-icon>
                                    </template>
                                    <span>Copy Campaign</span>
                                  </v-tooltip>
                                </span>
                                <v-tooltip
                                  top
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      color="info"
                                      @click="$_dashMixin_campaignHistory(calendar.selectedEvent.campaign.campaignId)"
                                      v-on="on"
                                    >
                                      mdi-book-open-page-variant
                                    </v-icon>
                                  </template>
                                  <span>Campaign History</span>
                                </v-tooltip>
                                <v-tooltip
                                  v-if="calendar.selectedEvent.campaign.campaignStatus === $_campaignStatus_Completed && calendar.selectedEvent.campaign.campaignFinished && ($_global_hasRouteClaims('CampaignEventsHeatmap') || $_global_hasRouteClaims('CampaignReport'))"
                                  top
                                >
                                  <template v-slot:activator="{ on: onTooltip }">
                                    <v-menu offset-y>
                                      <template v-slot:activator="{ on: onMenu, attrs }">
                                        <v-icon
                                          color="info"
                                          v-bind="attrs"
                                          v-on="{ ...onTooltip, ...onMenu }"
                                        >
                                          mdi-chart-box
                                        </v-icon>
                                      </template>
                                      <v-list>
                                        <v-list-item v-if="$_global_hasRouteClaims('CampaignEventsHeatmap') && $_dashMixin_isGeoDataFileTypeId(calendar.selectedEvent.campaign.targetAudienceFileTypeId)">
                                          <v-list-item-title>
                                            <router-link :to="{ name: 'CampaignEventsHeatmap', params: { campaignId: calendar.selectedEvent.campaign.campaignId } }">
                                              Campaign Heatmap
                                            </router-link>
                                          </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="$_global_hasRouteClaims('CampaignReport')">
                                          <v-list-item-title>
                                            <router-link :to="{ name: 'CampaignReport', params: { campaignId: calendar.selectedEvent.campaign.campaignId } }">
                                              Campaign Report
                                            </router-link>
                                          </v-list-item-title>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </template>
                                  <span>Reporting</span>
                                </v-tooltip>
                              </div>
                              <div v-if="calendar.selectedEvent.campaign.campaignName">
                                {{ calendar.selectedEvent.campaign.campaignName }}
                              </div>
                              <div v-if="calendar.selectedEvent.campaign.onBehalfOfName">
                                Contact: {{ calendar.selectedEvent.campaign.onBehalfOfName }}
                              </div>
                              <div v-if="calendar.selectedEvent.campaign.modifiedBy">
                                Modified by: {{ calendar.selectedEvent.campaign.modifiedBy }}
                              </div>
                              <v-row
                                class="pa-2"
                                justify="center"
                                align="start"
                              >
                                <v-col
                                  v-for="(mailing, index) in calendar.selectedEvent.mailings"
                                  :key="mailing.id"
                                  cols="auto"
                                  class="px-0 pb-0"
                                >
                                  <v-tooltip
                                    top
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-chip v-if="mailing.id === calendar.selectedEvent.mailing.id">
                                        <v-icon
                                          :color="$_dashMixin_getMailingIconColor(mailing)"
                                          large
                                          v-on="on"
                                        >
                                          {{ $_dashMixin_getMailingIcon(mailing) }}
                                        </v-icon>
                                      </v-chip>
                                      <v-icon
                                        v-else
                                        :color="$_dashMixin_getMailingIconColor(mailing)"
                                        class="ma-1"
                                        v-on="on"
                                      >
                                        {{ $_dashMixin_getMailingIcon(mailing) }}
                                      </v-icon>
                                    </template>
                                    <span>{{ mailing.statusDisplay }}</span>
                                  </v-tooltip>
                                  <template v-if="!calendar.selectedEvent.campaign.readOnlyActions">
                                    <template
                                      v-if="!mailing.isScheduledStatus"
                                    >
                                      <br>
                                      <v-tooltip
                                        top
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            :disabled="calendar.selectedEvent.campaign.isCanceled"
                                            :class="`my-1 mx-${mailing.id === calendar.selectedEvent.mailing.id ? '4' : '1'}`"
                                            color="success"
                                            @click="calendar.selectedOpen = false; $_dashMixin_continueMailing(mailing)"
                                            v-on="on"
                                          >
                                            mdi-pencil
                                          </v-icon>
                                        </template>
                                        <span>Continue Mailing</span>
                                      </v-tooltip>
                                      <br v-if="mailing.sequence > 1">
                                      <v-menu
                                        v-if="mailing.sequence > 1"
                                        right
                                        x-offset
                                        origin="right"
                                        transition="slide-x-transition"
                                      >
                                        <template v-slot:activator="{ on: onMenu }">
                                          <v-tooltip
                                            top
                                          >
                                            <template v-slot:activator="{ on: onTooltip }">
                                              <v-icon
                                                :disabled="calendar.selectedEvent.campaign.isCanceled"
                                                :class="`my-1 mx-${mailing.id === calendar.selectedEvent.mailing.id ? '4' : '1'}`"
                                                color="error"
                                                v-on="{ ...onMenu, ...onTooltip }"
                                              >
                                                mdi-close
                                              </v-icon>
                                            </template>
                                            <span>Delete Resend</span>
                                          </v-tooltip>
                                        </template>
                                        <v-card>
                                          <v-card-text class="pa-5">
                                            <span>Are you sure you want to delete this resend?</span>
                                          </v-card-text>
                                          <v-divider />
                                          <v-card-actions>
                                            <v-spacer />
                                            <v-btn
                                              color="tertiary"
                                              :disabled="isLoading()"
                                            >
                                              Cancel
                                            </v-btn>
                                            <v-btn
                                              color="error"
                                              :loading="isLoading()"
                                              @click="deleteResend(calendar.selectedEvent, mailing, index)"
                                            >
                                              Yes
                                            </v-btn>
                                          </v-card-actions>
                                        </v-card>
                                      </v-menu>
                                    </template>
                                    <br>
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          :disabled="!calendar.selectedEvent.campaign.canAddMailings"
                                          :class="`my-1 mx-${mailing.id === calendar.selectedEvent.mailing.id ? '4' : '1'}`"
                                          color="info"
                                          @click="calendar.selectedOpen = false; $_dashMixin_copyMailing(mailing)"
                                          v-on="on"
                                        >
                                          mdi-content-copy
                                        </v-icon>
                                      </template>
                                      <span>Add Copy of Mailing</span>
                                    </v-tooltip>
                                    <br v-if="mailing.isScheduledStatus">
                                    <template v-if="mailing.isScheduledStatus">
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            :disabled="calendar.selectedEvent.campaign.isCanceled || mailing.status === $_mailingItemStatus_Sent || mailing.status === $_mailingItemStatus_Sending"
                                            :class="`my-1 mx-${mailing.id === calendar.selectedEvent.mailing.id ? '4' : '1'}`"
                                            color="warning"
                                            @click="calendar.selectedOpen = false; $_dashMixin_unscheduleMailing(mailing)"
                                            v-on="on"
                                          >
                                            mdi-calendar-edit
                                          </v-icon>
                                        </template>
                                        <span>Unschedule Mailing</span>
                                      </v-tooltip>
                                    </template>
                                  </template>
                                  <br
                                    v-if="mailing.isScheduledStatus && ($_global_hasRouteClaims('MailingResultsDashboard') || (mailing.status === $_mailingItemStatus_Sent && $_global_hasRouteClaims('SingleMailingReport')))"
                                  >
                                  <v-tooltip
                                    v-if="mailing.isScheduledStatus && ($_global_hasRouteClaims('MailingResultsDashboard') || (mailing.status === $_mailingItemStatus_Sent && $_global_hasRouteClaims('SingleMailingReport')))"
                                    top
                                  >
                                    <template v-slot:activator="{ on: onTooltip }">
                                      <v-menu offset-y>
                                        <template v-slot:activator="{ on: onMenu, attrs }">
                                          <v-icon
                                            :class="`my-1 mx-${mailing.id === calendar.selectedEvent.mailing.id ? '4' : '1'}`"
                                            color="info"
                                            v-bind="attrs"
                                            v-on="{ ...onTooltip, ...onMenu }"
                                          >
                                            mdi-chart-box
                                          </v-icon>
                                        </template>
                                        <v-list>
                                          <v-list-item>
                                            <v-list-item-title v-if="$_global_hasRouteClaims('MailingResultsDashboard')">
                                              <router-link :to="{ name: 'MailingResultsDashboard', params: { campaignId: calendar.selectedEvent.campaign.campaignId, mailingItemId: mailing.id } }">
                                                Real Time Results
                                              </router-link>
                                            </v-list-item-title>
                                          </v-list-item>
                                          <v-list-item v-if="mailing.status === $_mailingItemStatus_Sent && $_global_hasRouteClaims('SingleMailingReport')">
                                            <v-list-item-title>
                                              <router-link :to="{ name: 'SingleMailingReport', params: { campaignId: calendar.selectedEvent.campaign.campaignId, mailingItemId: mailing.id } }">
                                                Single Mailing Report
                                              </router-link>
                                            </v-list-item-title>
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </template>
                                    <span>Reporting</span>
                                  </v-tooltip>
                                </v-col>
                                <template v-if="calendar.selectedEvent.campaign.numberOfBroadcasts >= calendar.selectedEvent.mailings.length && !calendar.selectedEvent.campaignExpired">
                                  <v-col
                                    v-for="i in calendar.selectedEvent.campaign.numberOfBroadcasts - calendar.selectedEvent.mailings.length"
                                    :key="i"
                                    cols="auto"
                                    class="px-0"
                                  >
                                    <v-tooltip
                                      top
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          color="grey"
                                          class="ma-1"
                                          v-on="on"
                                        >
                                          mdi-email-outline
                                        </v-icon>
                                      </template>
                                      <span>Not Created</span>
                                    </v-tooltip>
                                  </v-col>
                                </template>
                                <v-col />
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <!-- <v-spacer /> -->
                          <v-btn
                            color="secondary"
                            @click="closeCalendarEvent"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Final Broadcast Contacts
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ContactUploadTable final-broadcast />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="tertiary"
          class="ml-2"
          :disabled="isLoading()"
          @click="cancel()"
        >
          <v-icon left>
            mdi-menu-left-outline
          </v-icon>Back
        </v-btn>
        <v-menu
          v-model="submitMenu"
          x-offset
          origin="left"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on: onMenu }">
            <v-btn
              color="success"
              class="ml-2"
              :disabled="!!requiredToScheduleMailingId || !isTAComplete"
              :loading="isLoading()"
              @click="validateSubmitSchedule()"
            >
              Done<v-icon right>
                mdi-check-outline
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text class="pa-5">
              <span>{{ submitText }}</span>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="tertiary"
              >
                Close
              </v-btn>
              <v-btn
                color="success"
                @click="finalSubmitSchedule()"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="htmlDialog"
      scrollable
      max-width="1400"
    >
      <v-card>
        <!-- <v-card-title
          primary-title
        >
          Split
        </v-card-title>
        <v-divider /> -->
        <v-card-text
          class="pa-5"
          style="max-height: 75vh"
        >
          <MailingPreview
            :split-id="splitIdDialog"
            :mailing-item-id="mailingItemIdDialog"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="tertiary"
            @click="closeHtmlDialog()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  displayDateFormat,
  inputDateFormat,
  calendarEventFormat,
  displayTimeFormat2,
  purpleRedGradientDark16,
  greenGreyGradientLight8
} from '@/shared/constants'
import { validationMixin } from 'vuelidate'
import {
  required,
  requiredIf
} from 'vuelidate/lib/validators'
import {
  dateSlashMMDDYYYY,
  dateDashYYYYMMDD
} from '@/shared/validators'
import { winningColor, resendType } from '@/shared/models/mailing'
import { splitMixin, splitMixinMailing, dashboardMixin } from '@/shared/mixins/mailing'
import { enumsMixin } from '@/shared/mixins/general'
import { mapGetters, mapState } from 'vuex'
import claims from '@/shared/models/auth/claims'
import moment from 'moment'
import { mailingService } from '@/shared/services'

export default {
  name: 'ScheduleMailing',

  components: {
    MailingPreview: () => import('@/views/pages/components/mailing/MailingPreview'),
    ContactUploadTable: () => import('@/views/pages/components/mailing/ContactUploadTable')
  },

  mixins: [validationMixin, splitMixin, splitMixinMailing, dashboardMixin, enumsMixin],

  validations: {
    // scheduleDateSlash: {
    //   dateSlashMMDDYYYY
    // },
    // winningScheduleDateSlash: {
    //   dateSlashMMDDYYYY
    // },
    resendType: {
      requiredIf: requiredIf(function () {
        return this.getIsResend
      })
    },
    globalResends: {
      requiredIf: requiredIf(function () {
        return this.getIsResend && this.resendType !== 1
      })
    }
  },

  props: {
    stepVal: {
      type: Number,
      default: 3
    }
  },

  data: vm => ({
    color: 'accent',
    items: [],
    selectColor: 'green lighten-1',
    winningColor: winningColor,
    requiredToScheduleMailingId: null,
    scheduleDateSlashErrors: null,
    winningScheduleDateSlashErrors: null,
    splitSendDurationHours: 24,
    winningSendBufferHours: 3,
    prevSendDurationHours: 24,
    prevSendSTO: null,
    resendBufferHours: 48,
    globalResendsSelect: [],
    globalResendsLoading: false,
    splitIdDialog: null,
    mailingItemIdDialog: null,
    htmlDialog: false,
    submitMenu: false,
    submitText: null,
    currentCalendarItem: null,
    mailingStatusFilter: [1, 2, 3, 4, 5, 100],
    mailingStatues: [1, 2, 3, 100, 4, 5],
    calendar: {
      today: moment().format(calendarEventFormat),
      focus: moment().format(calendarEventFormat),
      lastRefreshedMonth: moment().month() + 1,
      type: 'month',
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      focusedEventMailingId: null,
      focusedEventCampaignId: null
    }
  }),

  created () {
    this.init()
  },

  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('auth', ['hasClaimKV']),
    ...mapState('mailing', ['sequence']),
    showTAActions () {
      return this.hasClaimKV(claims.ITA_DSC)
    },
    showScheduling () {
      return this.hasClaimKV(claims.BS_SPB)
    },
    getIsResend () {
      return this.sequence !== 1
    },
    scheduleDate: {
      get () {
        if (this.$store.getters['mailing/getScheduleDate']()) {
          var date = moment(this.$_global_parseAsUtc(this.$store.getters['mailing/getScheduleDate']()))
          if (date.isBefore(this.minDate.utc())) {
            return this.minDate.utc().format()
          }
          if (date.isAfter(this.maxDate.utc())) {
            if (this.$_splitMixin_isSplitTest) {
              return this.winningDateBufferSubtract(this.maxDate.utc()).format()
            }
            return this.maxDate.utc().format()
          }
          return date.format()
        }
        return this.minDate.utc().format()
      },
      set (value) {
        this.$store.commit('mailing/setMailingValue', { prop: 'scheduleDate', value })
        this.$store.dispatch('mailing/setMailingValue', { prop: 'scheduleDate' })
      }
    },
    scheduleDateLocal () {
      return this.scheduleDate ? moment(this.scheduleDate).local() : this.minDate.local()
    },
    scheduleDateDash: {
      get () {
        return this.scheduleDateLocal ? this.scheduleDateLocal.format(inputDateFormat) : null
      },
      set (val) {
        if (val) {
          this.scheduleDateSlashErrors = null
          this.scheduleDate = this.formatDateForSet(val, this.scheduleTime)
        }
      }
    },
    scheduleDateSlash: {
      get () {
        return this.scheduleDateLocal ? this.scheduleDateLocal.format(displayDateFormat) : null
      },
      set (val) {
        if (this.parseDate(val)) {
          this.scheduleDateSlashErrors = null
          this.scheduleDate = this.formatDateForSet(this.parseDate(val), this.scheduleTime)
        } else {
          this.scheduleDateSlashErrors = 'Schedule Date must be in format MM/DD/YYYY'
        }
      }
    },
    scheduleTime: {
      get () {
        return this.scheduleDateLocal ? this.scheduleDateLocal.format(displayTimeFormat2) : null
      },
      set (val) {
        this.scheduleDate = this.formatDateForSet(this.scheduleDateDash, val)
      }
    },
    winningScheduleDate: {
      get () {
        if (!this.$_splitMixin_isSplitTest) return null
        if (this.$store.getters['mailing/getWinningScheduleDate']()) {
          var date = moment(this.$_global_parseAsUtc(this.$store.getters['mailing/getWinningScheduleDate']()))
          if (date.isBefore(this.winningMinDate.utc())) {
            return this.winningMinDate.utc().format()
          }
          if (date.isAfter(this.maxDate.utc())) {
            return this.maxDate.utc().format()
          }
          return date.format()
        }
        return this.winningDateBuffer(moment(this.scheduleDate)).utc().format()
      },
      set (value) {
        if (this.$_splitMixin_isSplitTest) {
          this.$store.commit('mailing/setMailingValue', { prop: 'winningScheduleDate', value })
          this.$store.dispatch('mailing/setMailingValue', { prop: 'winningScheduleDate' })
        }
      }
    },
    winningScheduleDateLocal () {
      if (!this.$_splitMixin_isSplitTest) return null
      return this.winningScheduleDate ? moment(this.winningScheduleDate).local() : this.winningDateBuffer(moment(this.scheduleDate)).local().format()
    },
    winningScheduleDateDash: {
      get () {
        return this.winningScheduleDateLocal ? this.winningScheduleDateLocal.format(inputDateFormat) : null
      },
      set (val) {
        if (this.$_splitMixin_isSplitTest && val) {
          this.winningScheduleDateSlashErrors = null
          this.winningScheduleDate = this.formatDateForSet(val, this.winningScheduleTime)
        }
      }
    },
    winningScheduleDateSlash: {
      get () {
        return this.winningScheduleDateLocal ? this.winningScheduleDateLocal.format(displayDateFormat) : null
      },
      set (val) {
        if (this.$_splitMixin_isSplitTest) {
          if (this.parseDate(val)) {
            this.winningScheduleDateSlashErrors = null
            this.winningScheduleDate = this.formatDateForSet(this.parseDate(val), this.winningScheduleTime)
          } else {
            this.winningScheduleDateSlashErrors = 'Winning Schedule Date must be in format MM/DD/YYYY'
          }
        }
      }
    },
    winningScheduleTime: {
      get () {
        return this.winningScheduleDateLocal ? this.winningScheduleDateLocal.format(displayTimeFormat2) : null
      },
      set (val) {
        if (this.$_splitMixin_isSplitTest) {
          this.winningScheduleDate = this.formatDateForSet(this.winningScheduleDateDash, val)
        }
      }
    },
    prevScheduleDate () {
      if (this.$store.getters['mailing/getPrevScheduleDate']()) {
        return moment(this.$_global_parseAsUtc(this.$store.getters['mailing/getPrevScheduleDate']())).utc()
      }
      return null
    },
    prevScheduleDateLocal () {
      if (this.$store.getters['mailing/getPrevScheduleDate']()) {
        return moment(this.$_global_parseAsUtc(this.$store.getters['mailing/getPrevScheduleDate']())).local()
      }
      return null
    },
    showPrevSchedulingMessage () {
      return this.prevScheduleDate && this.minDate.isSame(this.mailingDateBuffer(moment(this.prevScheduleDate)))
    },
    minDate () {
      var dates = [this.presentDateBuffer(moment())]
      if (this.prevScheduleDate) {
        dates.push(this.mailingDateBuffer(moment(this.prevScheduleDate)))
      }
      return moment.max(dates)
    },
    minTime () {
      if (this.scheduleDateDash === this.minDate.local().format(inputDateFormat)) {
        return this.minDate.local().format(displayTimeFormat2)
      }
      return null
    },
    winningMinDate () {
      return this.winningDateBuffer(moment(this.scheduleDate))
    },
    winningMinTime () {
      if (moment(this.winningScheduleDate).isSame(this.winningDateBuffer(moment(this.scheduleDate)).format(inputDateFormat), 'day')) {
        return this.winningDateBuffer(moment(this.scheduleDate)).format(displayTimeFormat2)
      }
      return null
    },
    maxDate () {
      return this.$store.getters['mailing/getMaxScheduleDate']() ? moment(this.$_global_parseAsUtc(this.$store.getters['mailing/getMaxScheduleDate']())).local() : moment().add(100, 'y').local()
    },
    calendarTitle () {
      const { start, end } = this.calendar
      if (!start || !end) {
        return ''
      }

      const startMonth = this.monthFormatter(start)
      const endMonth = this.monthFormatter(end)
      const suffixMonth = startMonth === endMonth ? '' : endMonth

      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : endYear

      const startDay = start.day + this.nth(start.day)
      const endDay = end.day + this.nth(end.day)

      switch (this.calendar.type) {
        case 'month':
          return `${startMonth} ${startYear}`
        case 'week':
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`
      }
      return ''
    },
    monthFormatter () {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC',
        month: 'long'
      })
    },
    mailingsToEvents () {
      if (!this.items || !this.currentCalendarItem) return []
      var initList = []
      var currentMailing = this.currentCalendarItem.mailings.find(x => x.id === this.$store.getters['mailing/getMailingItemId']())
      const { mailings, targetAudience, ...currentElemSpread } = this.currentCalendarItem
      let endMinutesFrom = 15
      let scheduleDateLocalClone = this.scheduleDateLocal.clone()
      var current = {
        mailing: {
          ...currentMailing,
          modifiedOnLocal: this.$_dashMixin_dateToLocal(currentMailing.modifiedOn)
        },
        mailings: mailings.map((item) => ({
          id: item.id,
          status: item.status,
          statusDisplay: item.statusDisplay,
          discriminator: item.discriminator,
          sequence: item.sequence,
          name: item.name,
          isScheduledStatus: item.isScheduledStatus,
          splits: item.splits.map((splitItem) => ({
            id: splitItem.id
          }))
        })),
        targetAudience: { ...targetAudience },
        campaign: { ...currentElemSpread },
        name: `${this.currentCalendarItem.campaignName} - ${currentMailing.name}`,
        details: `${this.currentCalendarItem.campaignName} - ${currentMailing.name}`,
        start: scheduleDateLocalClone.format(calendarEventFormat),
        end: scheduleDateLocalClone.add(endMinutesFrom, 'minutes').format(calendarEventFormat),
        color: this.selectColor,
        isWinner: false
      }
      initList.push(current)
      if (this.$_splitMixin_isSplitTest) {
        let winningDateLocalClone = this.winningScheduleDateLocal.clone()
        initList.push({
          ...current,
          name: `${this.currentCalendarItem.campaignName} - ${currentMailing.name} (Winner)`,
          details: `${this.currentCalendarItem.campaignName} - ${currentMailing.name} (Winner)`,
          start: winningDateLocalClone.format(calendarEventFormat),
          end: winningDateLocalClone.add(endMinutesFrom, 'minutes').format(calendarEventFormat),
          isWinner: true,
          color: this.winningColor
        })
      }
      return this.items.reduce((arr, elem) => {
        if (elem.mailings) {
          const { mailings, targetAudience, ...elemSpread } = elem
          for (const m of elem.mailings.filter(x => x.id !== this.$store.getters['mailing/getMailingItemId']() && this.mailingStatusFilter.includes(x.status))) {
            let startDate = m.isScheduledStatus ? m.scheduleDate : m.modifiedOn
            let start = moment(this.$_dashMixin_dateToLocal(startDate)).format(calendarEventFormat)
            let end = moment(this.$_dashMixin_dateToLocal(startDate)).add(endMinutesFrom, 'minutes').format(calendarEventFormat)
            var mail = {
              mailing: {
                ...m,
                modifiedOnLocal: this.$_dashMixin_dateToLocal(m.modifiedOn)
              },
              mailings: mailings.map((item) => ({
                id: item.id,
                status: item.status,
                statusDisplay: item.statusDisplay,
                discriminator: item.discriminator,
                sequence: item.sequence,
                name: item.name,
                isScheduledStatus: item.isScheduledStatus,
                splits: item.splits.map((splitItem) => ({
                  id: splitItem.id
                }))
              })),
              targetAudience: { ...targetAudience },
              campaign: { ...elemSpread },
              name: `${elem.campaignName} - ${m.name}`,
              details: `${elem.campaignName} - ${m.name}`,
              start: start,
              end: end,
              color: this.$_dashMixin_getMailingIconColor(m), // campaignColor,
              isWinner: false
            }
            arr.push(mail)
            if (m.isScheduledStatus && m.winningScheduleDate) {
              arr.push({
                ...mail,
                name: `${elem.campaignName} - ${m.name} (Winner)`,
                details: `${elem.campaignName} - ${m.name} (Winner)`,
                start: moment(this.$_dashMixin_dateToLocal(m.winningScheduleDate)).format(calendarEventFormat),
                end: moment(this.$_dashMixin_dateToLocal(m.winningScheduleDate)).add(endMinutesFrom, 'minutes').format(calendarEventFormat),
                isWinner: true
              })
            }
          }
        }
        return arr
      }, initList)
    },
    resendType: {
      get () {
        return this.$store.getters['mailing/getResendType']()
      },
      set (value) {
        this.$store.commit('mailing/setMailingValue', { prop: 'resendType', value })
      }
    },
    resendTypeSelect () {
      return resendType
    },
    resendTypeErrors () {
      const errors = []
      if (!this.$v.resendType.$dirty) return errors
      !this.$v.resendType.requiredIf &&
        errors.push('Resend Type is required')
      return errors
    },
    globalResends: {
      get () {
        return this.$store.getters['mailing/getGlobalResends']()
      },
      set (value) {
        this.$store.commit('mailing/setMailingValue', { prop: 'globalResends', value })
      }
    },
    globalResendsErrors () {
      const errors = []
      if (!this.$v.globalResends.$dirty) return errors
      !this.$v.globalResends.requiredIf &&
        errors.push('Global Resend Audience is required')
      return errors
    },
    isTASelected () {
      return this.$store.getters['mailing/getTargetId']() !== null
    },
    isTAComplete () {
      return this.$store.getters['mailing/getTargetAudienceStatus']() === this.$_taStatus_Completed
    }
  },

  watch: {
    scheduleDate (newValue, oldValue) {
      this.checkWinningDate()
      this.setCalendarFocus(newValue)
    },
    winningScheduleDate (newValue, oldValue) {
      this.checkWinningDate()
      this.setCalendarFocus(newValue)
    }
  },

  methods: {
    async init () {
      this.setCalendarFocus(this.scheduleDateLocal)
      this.requiredToScheduleMailingId = await mailingService.getRequiredToScheduleId({
        campaignId: this.$store.getters['mailing/getCampaignId'](),
        currentMailingId: this.$store.getters['mailing/getMailingItemId']()
      })
      this.loadCurrentCalendarItem()
      this.loadScheduledMailings()
      if (this.$_splitMixin_isSplitTest) {
        this.loadSplitSendHours()
      }
      if (this.getIsResend) {
        this.loadGlobalResends()
        this.loadPreviousMailingSTO()
      }
    },
    destroy () {

    },
    setMailingValue (prop) {
      this.$store.dispatch('mailing/setMailingValue', { prop })
    },
    setCalendarFocus (date) {
      this.calendar.focus = moment(date).format(calendarEventFormat)
    },
    checkWinningDate () {
      if (this.$_splitMixin_isSplitTest &&
      this.winningDateBuffer(moment(this.scheduleDate)).isSameOrAfter(moment(this.winningScheduleDate), 'day')) {
        this.winningScheduleDateDash = this.winningDateBuffer(moment(this.scheduleDate)).format(inputDateFormat)
        if (moment(this.winningDateBuffer(moment(this.scheduleDate)).format(displayTimeFormat2), displayTimeFormat2).isAfter(moment(this.winningScheduleTime, displayTimeFormat2), 'minute')) {
          this.winningScheduleTime = this.scheduleTime
        }
      }
    },
    cancel () {
      this.$emit('cancelStep', this.stepVal)
    },
    validateSubmitSchedule () {
      if (this.$v.$invalid || this.scheduleDateSlashErrors || this.winningScheduleDateSlashErrors) {
        this.$vuetify.goTo(0)
        this.$v.$touch()
        return
      }

      if (this.minTime !== null && this.scheduleDateLocal.isBefore(moment().local(), 'minute')) {
        this.submitText = `You've scheduled your mailing to send ${moment().local().add(1, 'm').diff(this.scheduleDateLocal, 'minutes')} minute(s) in the past. This will send immediately. Are you sure?`
        this.submitMenu = true
        return
      }
      if (this.minTime !== null && this.scheduleDateLocal.isSame(moment().local(), 'minute')) {
        this.submitText = `You've scheduled your mailing to send right now. Are you sure?`
        this.submitMenu = true
        return
      }
      if (this.minTime !== null && this.scheduleDateLocal.isSameOrBefore(moment().add(1, 'h').local(), 'minute')) {
        this.submitText = `You've scheduled your mailing to send in ${this.scheduleDateLocal.diff(moment().add(-1, 'm').local(), 'minutes')} minute(s). Are you sure?`
        this.submitMenu = true
        return
      }

      this.finalSubmitSchedule()
    },
    finalSubmitSchedule () {
      // save date values incase they did not change (they would have never been saved then)
      this.scheduleDateDash = this.scheduleDateDash
      if (this.$_splitMixin_isSplitTest) {
        this.winningScheduleDateDash = this.winningScheduleDateDash
      }
      this.$emit('advanceFromStep', this.stepVal)
    },
    async loadCurrentCalendarItem () {
      mailingService
        .getCampaignDashboardItem(this.$store.getters['mailing/getCampaignId']())
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.currentCalendarItem = []
          } else {
            this.currentCalendarItem =
              {
                ...resp,
                modifiedOnLocal: moment(this.$_dashMixin_dateToLocal(resp.modifiedOn)),
                _expandedIcon: 'down',
                targetAudience: {
                  ...resp.targetAudience,
                  orderDateLocal: resp.targetAudience && resp.targetAudience.orderDate ? moment(this.$_dashMixin_dateToLocal(resp.targetAudience.orderDate)) : null
                }
              }
          }
        })
    },
    async loadScheduledMailings () {
      mailingService
        .getCampaignDashboard({
          viewAsCustomer: this.$store.getters['simulatedCustomerCrmId'](),
          days: 0,
          filterUser: 0,
          status: null,
          viewMode: 'calendar',
          viewModeCalendarStart: moment(this.calendar.focus)
        })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.items = []
          } else {
            this.items = resp.map(x => (
              {
                ...x,
                modifiedOnLocal: moment(this.$_dashMixin_dateToLocal(x.modifiedOn)),
                _expandedIcon: 'down',
                targetAudience: {
                  ...x.targetAudience,
                  orderDateLocal: x.targetAudience && x.targetAudience.orderDate ? moment(this.$_dashMixin_dateToLocal(x.targetAudience.orderDate)) : null
                }
              }
            ))
            this.calendar.lastRefreshedMonth = moment(this.calendar.focus).month() + 1
          }
        })
    },
    async loadGlobalResends () {
      this.globalResendsLoading = true
      var resp = await mailingService.getGlobalResendsList(this.$store.getters['mailing/getMailingItemId']())
      this.globalResendsSelect = resp
      this.globalResendsLoading = false
    },
    async loadSplitSendHours () {
      var resp = await mailingService.getSplitSendHours(this.$store.getters['mailing/getMailingItemId']())
      if (!resp.errorResponse) {
        this.splitSendDurationHours = resp
      }
    },
    async loadPreviousMailingSTO () {
      var resp = await mailingService.getPreviousSendSTO(this.$store.getters['mailing/getMailingItemId']())
      if (!resp.errorResponse) {
        this.prevSendDurationHours = resp.totalIterableHours
        this.prevSendSTO = resp
        this.prevSendSTO.sendStartLocal = this.$_dashMixin_dateToLocal(resp.utcStart)
        this.prevSendSTO.sendEndLocal = this.$_dashMixin_dateToLocal(resp.utcEnd)
      }
    },
    viewDay ({ date }) {
      this.calendar.focus = date
      this.calendar.type = 'day'
    },
    getEventColor (event) {
      if (this.calendar.focusedEventCampaignId === null ||
      this.calendar.focusedEventCampaignId === event.campaign.campaignId) {
        return event.color
      }
      return 'grey lighten-2'
    },
    setToday () {
      this.calendar.focus = this.calendar.today
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        // this.calendar.selectedEvent = { mailings: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }] }
        this.calendar.selectedEvent = event
        this.calendar.selectedElement = nativeEvent.target
        this.calendar.focusedEventMailingId = event.mailing.id
        this.calendar.focusedEventCampaignId = event.campaign.campaignId
        setTimeout(() => (this.calendar.selectedOpen = true), 10)
      }

      if (this.calendar.selectedOpen) {
        this.calendar.selectedOpen = false
        this.closeCalendarEvent()
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange ({ start, end }) {
      var prevStart = this.calendar.start
      var prevEnd = this.calendar.end
      this.calendar.start = start
      this.calendar.end = end
      if (prevStart && prevEnd && this.calendar.lastRefreshedMonth !== start.month && start.month === end.month && (prevStart.month !== start.month || prevEnd.month !== end.month)) {
        this.loadScheduledMailings()
      }
    },
    nth (d) {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },
    mouseEnterEvent (event) {
      if (!this.calendar.selectedOpen) {
        this.calendar.focusedEventMailingId = event.event.mailing.id
        this.calendar.focusedEventCampaignId = event.event.campaign.campaignId
      }
    },
    mouseLeaveEvent (event) {
      if (!this.calendar.selectedOpen) {
        this.calendar.focusedEventMailingId = null
        this.calendar.focusedEventCampaignId = null
      }
    },
    closeCalendarEvent () {
      this.calendar.selectedOpen = false
      this.calendar.focusedEventMailingId = null
      this.calendar.focusedEventCampaignId = null
    },
    pastDateColor (date) {
      let dateMoment = moment(date).format()
      let stepper = [
        { val: 3, period: 'd' },
        { val: 1, period: 'w' },
        { val: 2, period: 'w' },
        { val: 3, period: 'w' },
        { val: 1, period: 'M' },
        { val: 2, period: 'M' },
        { val: 4, period: 'M' }
      ]
      if (
        dateMoment >
        moment()
          .endOf('day')
          .format()
      ) { return 'purple lighten-3' }
      for (let i = 0; i < greenGreyGradientLight8.length; i++) {
        if (i === greenGreyGradientLight8.length - 1) { return greenGreyGradientLight8[i].color }
        if (
          dateMoment >
          moment()
            .endOf('day')
            .subtract(stepper[i].val, stepper[i].period)
            .format()
        ) { return greenGreyGradientLight8[i].color }
      }
    },
    formatDate (date) {
      if (!date || !dateDashYYYYMMDD(date)) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date || !dateSlashMMDDYYYY(date)) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatDateForSet (date, time) {
      return moment(`${date}T${time}:00`).utc().format()
    },
    winningDateBuffer (momentObj) {
      let copy = moment(momentObj)
      return copy.add(this.splitSendDurationHours, 'h').add(this.winningSendBufferHours, 'h')
    },
    winningDateBufferSubtract (momentObj) {
      let copy = moment(momentObj)
      return copy.subtract(this.splitSendDurationHours, 'h').subtract(this.winningSendBufferHours, 'h')
    },
    presentDateBuffer (momentObj) {
      let copy = moment(momentObj)
      return copy.add(5, 'm')
    },
    mailingDateBuffer (momentObj) {
      let copy = moment(momentObj)
      return copy.add(this.prevSendDurationHours, 'h').add(this.resendBufferHours, 'h')
    },
    goToRequiredToScheduleMailing () {
      this.$_dashMixin_continueMailing({ id: this.requiredToScheduleMailingId })
    },
    goToEditTA () {
      this.$router.push({
        name: 'NowwRedirect',
        params: {
          nowwRoute: `count-start-submit.aspx?jobnumber=${this.$store.getters['mailing/getCampaignNowwOrderNumber']()}&FileTypeID=${this.$store.getters['mailing/getCampaignFileTypeId']()}&MainScreenGroupID=1`
        }
      })
    },
    async getSplitHtmlDialog (id, mailingId) {
      this.htmlDialog = true
      this.splitIdDialog = id
      this.mailingItemIdDialog = mailingId
    },
    closeHtmlDialog () {
      this.htmlDialog = false
      this.splitIdDialog = ''
      this.mailingItemIdDialog = ''
    },
    async deleteResend (campaign, mail, index) {
      this.calendar.selectedOpen = false
      await this.$_dashMixin_deleteResend(campaign, mail, index)
      await this.loadScheduledMailings()
    },
    async copyCampaign (campaign) {
      this.calendar.selectedOpen = false
      await this.$_dashMixin_copyCampaign(campaign)
    }
  }
}
</script>

<style lang="scss">
.v-progress-linear {
  display: inline-block;
  vertical-align: middle;
}

.v-skeleton-loader__date-picker-days > div.v-skeleton-loader__avatar.v-skeleton-loader__bone {
  height: 100px !important;
  width: 14% !important;
}
</style>
