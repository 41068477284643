var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          !_vm.showScheduling
            ? _c(
                "v-card-text",
                { staticClass: "font-weight-bold" },
                [
                  _vm._v(
                    "\n      You do not have permissions to schedule and perform broadcasts.\n      "
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { color: "success" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "CampaignDashboard" })
                        }
                      }
                    },
                    [_vm._v("\n        Ok\n      ")]
                  )
                ],
                1
              )
            : _vm.requiredToScheduleMailingId
            ? _c(
                "v-card-text",
                { staticClass: "font-weight-bold" },
                [
                  _vm._v(
                    "\n      You must first schedule a previous mailing from the campaign.\n      "
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { color: "success" },
                      on: {
                        click: function($event) {
                          return _vm.goToRequiredToScheduleMailing()
                        }
                      }
                    },
                    [
                      _vm._v("\n        Let's go\n        "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("\n          mdi-menu-right-outline\n        ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : !_vm.isTAComplete
            ? _c(
                "v-card-text",
                { staticClass: "font-weight-bold" },
                [
                  _vm._v(
                    "\n      You must first complete your Target Audience.\n      "
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { color: "success" },
                      on: {
                        click: function($event) {
                          return _vm.goToEditTA()
                        }
                      }
                    },
                    [
                      _vm._v("\n        Let's go\n        "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("\n          mdi-menu-right-outline\n        ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-card-text",
                { staticClass: "pa-2" },
                [
                  _c("p", { staticClass: "font-italic" }, [
                    _vm._v(
                      "\n        All times are displayed in local time. Broadcast mailings will be sent utilizing send time optimization based on the Target Audience size.\n        "
                    )
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "justify-space-between mb-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-center",
                          attrs: { cols: "12", lg: "6" }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "justify-center" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-center",
                                  attrs: { cols: "12" }
                                },
                                [_c("h2", [_vm._v("Schedule Date")])]
                              ),
                              _vm._l(_vm.splitMixin_splits, function(
                                split,
                                index
                              ) {
                                return _c(
                                  "v-col",
                                  {
                                    key: index,
                                    staticClass:
                                      "d-flex justify-center align-center pa-1",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _vm.currentCalendarItem &&
                                    _vm.currentCalendarItem.targetAudience
                                      ? _c(
                                          "span",
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.splitMixin_splits
                                                    .length === 1
                                                    ? ""
                                                    : "Split"
                                                ) +
                                                "\n                "
                                            ),
                                            _vm.splitMixin_splits.length > 1
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color:
                                                        _vm
                                                          .$_splitMixin_splitColors[
                                                          index
                                                        ]
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.getSplitHtmlDialog(
                                                          split.id,
                                                          _vm.$store.getters[
                                                            "mailing/getMailingItemId"
                                                          ]()
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "title" },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$_splitMixin_indexToChar(
                                                                index
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.splitMixin_splits
                                                    .length === 1
                                                    ? "This mailing"
                                                    : "(" +
                                                        split.distribution +
                                                        "%)"
                                                ) +
                                                " will broadcast over\n                "
                                            ),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$_dashMixin_countSendDaysShortLabel(
                                                    Math.ceil(
                                                      (split.distribution *
                                                        _vm.currentCalendarItem
                                                          .targetAudience
                                                          .trueCount) /
                                                        100
                                                    )
                                                  ).value
                                                )
                                              )
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$_dashMixin_countSendDaysShortLabel(
                                                    Math.ceil(
                                                      (split.distribution *
                                                        _vm.currentCalendarItem
                                                          .targetAudience
                                                          .trueCount) /
                                                        100
                                                    )
                                                  ).label
                                                ) +
                                                "\n              "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              }),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-center",
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    lg: "6",
                                    xl: "5"
                                  }
                                },
                                [
                                  _c("v-date-picker", {
                                    staticClass: "mx-2",
                                    attrs: {
                                      elevation: "4",
                                      "header-color": "primary",
                                      color: _vm.selectColor,
                                      min: _vm.minDate.local().format(),
                                      max: _vm.maxDate.local().format(),
                                      "show-current": ""
                                    },
                                    model: {
                                      value: _vm.scheduleDateDash,
                                      callback: function($$v) {
                                        _vm.scheduleDateDash = $$v
                                      },
                                      expression: "scheduleDateDash"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-center",
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    lg: "6",
                                    xl: "5"
                                  }
                                },
                                [
                                  _c("v-time-picker", {
                                    staticClass: "mx-2",
                                    attrs: {
                                      elevation: "4",
                                      "header-color": "primary",
                                      color: _vm.selectColor,
                                      "ampm-in-title": "",
                                      scrollable: "",
                                      format: "ampm",
                                      min: _vm.minTime
                                    },
                                    model: {
                                      value: _vm.scheduleTime,
                                      callback: function($$v) {
                                        _vm.scheduleTime = $$v
                                      },
                                      expression: "scheduleTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm.$_splitMixin_isSplitTest
                        ? _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-center",
                              attrs: { cols: "12", lg: "6" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "justify-center" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-center",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("h2", [
                                        _vm._v("Winning Schedule Date")
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-center align-center pa-1",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _vm.currentCalendarItem &&
                                      _vm.currentCalendarItem.targetAudience
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                Winner (" +
                                                _vm._s(
                                                  _vm.$store.getters[
                                                    "mailing/getWinningDistribution"
                                                  ]()
                                                ) +
                                                "%) will broadcast over\n                "
                                            ),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$_dashMixin_countSendDaysShortLabel(
                                                    Math.ceil(
                                                      (_vm.$store.getters[
                                                        "mailing/getWinningDistribution"
                                                      ]() *
                                                        _vm.currentCalendarItem
                                                          .targetAudience
                                                          .trueCount) /
                                                        100
                                                    )
                                                  ).value
                                                )
                                              )
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$_dashMixin_countSendDaysShortLabel(
                                                    Math.ceil(
                                                      (_vm.$store.getters[
                                                        "mailing/getWinningDistribution"
                                                      ]() *
                                                        _vm.currentCalendarItem
                                                          .targetAudience
                                                          .trueCount) /
                                                        100
                                                    )
                                                  ).label
                                                ) +
                                                "\n              "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-center",
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "4",
                                        lg: "6",
                                        xl: "5"
                                      }
                                    },
                                    [
                                      _c("v-date-picker", {
                                        staticClass: "mx-2",
                                        attrs: {
                                          elevation: "4",
                                          "header-color": _vm.winningColor,
                                          color: _vm.selectColor,
                                          min: _vm.winningMinDate.format(),
                                          max: _vm.maxDate.local().format(),
                                          "show-current": false
                                        },
                                        model: {
                                          value: _vm.winningScheduleDateDash,
                                          callback: function($$v) {
                                            _vm.winningScheduleDateDash = $$v
                                          },
                                          expression: "winningScheduleDateDash"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-center",
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "4",
                                        lg: "6",
                                        xl: "5"
                                      }
                                    },
                                    [
                                      _c("v-time-picker", {
                                        staticClass: "mx-2",
                                        attrs: {
                                          elevation: "4",
                                          "header-color": _vm.winningColor,
                                          color: _vm.selectColor,
                                          "ampm-in-title": "",
                                          scrollable: "",
                                          format: "ampm",
                                          min: _vm.winningMinTime
                                        },
                                        model: {
                                          value: _vm.winningScheduleTime,
                                          callback: function($$v) {
                                            _vm.winningScheduleTime = $$v
                                          },
                                          expression: "winningScheduleTime"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.getIsResend
                        ? _c(
                            "v-col",
                            {
                              staticClass: "d-flex",
                              attrs: { cols: "12", sm: "9", md: "6" }
                            },
                            [
                              _c("v-select", {
                                staticClass: "px-2 pt-3",
                                attrs: {
                                  items: _vm.resendTypeSelect,
                                  dense: "",
                                  color: "primary",
                                  "item-color": "primary",
                                  label: "Resend Type",
                                  "error-messages": _vm.resendTypeErrors
                                },
                                on: {
                                  change: function($event) {
                                    _vm.$v.resendType.$touch()
                                    _vm.setMailingValue("resendType")
                                  }
                                },
                                model: {
                                  value: _vm.resendType,
                                  callback: function($$v) {
                                    _vm.resendType = $$v
                                  },
                                  expression: "resendType"
                                }
                              }),
                              _c("v-select", {
                                staticClass: "px-2 pt-4",
                                attrs: {
                                  items: _vm.globalResendsSelect,
                                  loading: _vm.globalResendsLoading,
                                  disabled:
                                    _vm.resendType ===
                                    _vm.$_resendType_FullFile,
                                  dense: "",
                                  multiple: "",
                                  color: "primary",
                                  "item-color": "primary",
                                  label: "Global Resend Audience",
                                  "error-messages": _vm.globalResendsErrors
                                },
                                on: {
                                  change: function($event) {
                                    _vm.$v.globalResends.$touch()
                                    _vm.setMailingValue("globalResends")
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          index === 0
                                            ? _c("span", [
                                                _vm._v(_vm._s(item.text))
                                              ])
                                            : _vm._e(),
                                          index === 1
                                            ? _c("span", [
                                                _vm._v(", " + _vm._s(item.text))
                                              ])
                                            : _vm._e(),
                                          index === 2
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "grey--text text-caption"
                                                },
                                                [
                                                  _vm._v(
                                                    " (+" +
                                                      _vm._s(
                                                        _vm.globalResends
                                                          .length - 2
                                                      ) +
                                                      " others)"
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1611840577
                                ),
                                model: {
                                  value: _vm.globalResends,
                                  callback: function($$v) {
                                    _vm.globalResends = $$v
                                  },
                                  expression: "globalResends"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$_splitMixin_isSplitTest
                        ? _c("v-col", { attrs: { cols: "12" } }, [
                            _c("p", { staticClass: "font-italic" }, [
                              _vm._v(
                                "\n            To preserve the integrity of the Split Test results, the Test Contacts will "
                              ),
                              _c("strong", [_vm._v("not")]),
                              _vm._v(
                                " receive a copy of the Split Tests, but will receieve a copy of the Winner.\n          "
                              )
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-expansion-panels",
                    { attrs: { multiple: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              "\n            Campaign Calendar\n          "
                            )
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-4",
                                          attrs: { color: _vm.color },
                                          on: { click: _vm.setToday }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Today\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mx-1",
                                          attrs: {
                                            fab: "",
                                            small: "",
                                            color: _vm.color
                                          },
                                          on: { click: _vm.prev }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                    mdi-chevron-left\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mx-1",
                                          attrs: {
                                            fab: "",
                                            small: "",
                                            color: _vm.color
                                          },
                                          on: { click: _vm.next }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                    mdi-chevron-right\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        { staticClass: "text-h4 mx-4" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.calendarTitle) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-btn-toggle",
                                        {
                                          attrs: {
                                            dense: "",
                                            mandatory: "",
                                            multiple: ""
                                          },
                                          model: {
                                            value: _vm.mailingStatusFilter,
                                            callback: function($$v) {
                                              _vm.mailingStatusFilter = $$v
                                            },
                                            expression: "mailingStatusFilter"
                                          }
                                        },
                                        _vm._l(_vm.mailingStatues, function(n) {
                                          return _c(
                                            "v-btn",
                                            {
                                              key: n,
                                              attrs: {
                                                value: n,
                                                color: _vm.$_dashMixin_getMailingIconColor(
                                                  { status: n }
                                                ),
                                                text: ""
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$_dashMixin_getMailingIcon(
                                                        {
                                                          discriminator:
                                                            "MailingItem",
                                                          status: n
                                                        }
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-btn-toggle",
                                        {
                                          staticClass: "pl-1",
                                          attrs: {
                                            dense: "",
                                            mandatory: "",
                                            color: _vm.color
                                          },
                                          model: {
                                            value: _vm.calendar.type,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.calendar,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression: "calendar.type"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "day" } },
                                            [
                                              _vm._v(
                                                "\n                    Day\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "week" } },
                                            [
                                              _vm._v(
                                                "\n                    Week\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "month" } },
                                            [
                                              _vm._v(
                                                "\n                    Month\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-sheet",
                                        { attrs: { "min-height": "600" } },
                                        [
                                          _vm.isLoading()
                                            ? _c("v-skeleton-loader", {
                                                attrs: { type: "date-picker" }
                                              })
                                            : _vm._e(),
                                          _c("v-calendar", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isLoading(),
                                                expression: "!isLoading()"
                                              }
                                            ],
                                            ref: "calendar",
                                            attrs: {
                                              color: _vm.color,
                                              events: _vm.mailingsToEvents,
                                              "event-color": _vm.getEventColor,
                                              "event-margin-bottom": 3,
                                              now: _vm.calendar.today,
                                              type: _vm.calendar.type
                                            },
                                            on: {
                                              "click:event": _vm.showEvent,
                                              "click:more": _vm.viewDay,
                                              "click:date": _vm.viewDay,
                                              "mouseenter:event":
                                                _vm.mouseEnterEvent,
                                              "mouseleave:event":
                                                _vm.mouseLeaveEvent,
                                              change: _vm.updateRange
                                            },
                                            model: {
                                              value: _vm.calendar.focus,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.calendar,
                                                  "focus",
                                                  $$v
                                                )
                                              },
                                              expression: "calendar.focus"
                                            }
                                          }),
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "close-on-content-click": false,
                                                "close-on-click": false,
                                                activator:
                                                  _vm.calendar.selectedElement,
                                                "offset-x": ""
                                              },
                                              model: {
                                                value:
                                                  _vm.calendar.selectedOpen,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.calendar,
                                                    "selectedOpen",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "calendar.selectedOpen"
                                              }
                                            },
                                            [
                                              _vm.calendar.selectedEvent &&
                                              _vm.calendar.selectedEvent
                                                .targetAudience &&
                                              _vm.calendar.selectedEvent
                                                .campaign &&
                                              _vm.calendar.selectedEvent
                                                .mailing &&
                                              _vm.calendar.selectedEvent.mailing
                                                .splits
                                                ? _c(
                                                    "v-card",
                                                    {
                                                      attrs: {
                                                        color: "grey lighten-4",
                                                        "min-width": "400px",
                                                        flat: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-toolbar",
                                                        {
                                                          attrs: {
                                                            color:
                                                              _vm.calendar
                                                                .selectedEvent
                                                                .color,
                                                            dark: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-toolbar-title",
                                                            {
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  _vm.calendar
                                                                    .selectedEvent
                                                                    .name
                                                                )
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-text",
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _vm.calendar
                                                                    .selectedEvent
                                                                    .mailing
                                                                    .splits &&
                                                                  _vm.calendar
                                                                    .selectedEvent
                                                                    .mailing
                                                                    .splits
                                                                    .length ===
                                                                    1
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .calendar
                                                                                  .selectedEvent
                                                                                  .mailing
                                                                                  .splits[0]
                                                                                  .subject
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._l(
                                                                            _vm
                                                                              .calendar
                                                                              .selectedEvent
                                                                              .mailing
                                                                              .splits,
                                                                            function(
                                                                              split,
                                                                              i
                                                                            ) {
                                                                              return _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  key: i,
                                                                                  attrs: {
                                                                                    top:
                                                                                      ""
                                                                                  },
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key:
                                                                                          "activator",
                                                                                        fn: function(
                                                                                          ref
                                                                                        ) {
                                                                                          var on =
                                                                                            ref.on
                                                                                          return [
                                                                                            _c(
                                                                                              "v-progress-linear",
                                                                                              _vm._g(
                                                                                                {
                                                                                                  style:
                                                                                                    "width: " +
                                                                                                    split.distribution +
                                                                                                    "%",
                                                                                                  attrs: {
                                                                                                    striped:
                                                                                                      "",
                                                                                                    height:
                                                                                                      "20",
                                                                                                    value:
                                                                                                      "100",
                                                                                                    color:
                                                                                                      _vm
                                                                                                        .$_splitMixin_splitColors[
                                                                                                        i
                                                                                                      ]
                                                                                                  },
                                                                                                  scopedSlots: _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key:
                                                                                                          "default",
                                                                                                        fn: function(
                                                                                                          ref
                                                                                                        ) {
                                                                                                          var value =
                                                                                                            ref.value
                                                                                                          return [
                                                                                                            _c(
                                                                                                              "strong",
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  _vm._s(
                                                                                                                    split.distribution
                                                                                                                  ) +
                                                                                                                    "%"
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ]
                                                                                                        }
                                                                                                      }
                                                                                                    ],
                                                                                                    null,
                                                                                                    true
                                                                                                  )
                                                                                                },
                                                                                                on
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        }
                                                                                      }
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  )
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          split.subject ||
                                                                                            "Subject " +
                                                                                              _vm
                                                                                                .$_splitMixin_splitLetters[
                                                                                                i
                                                                                              ]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                top:
                                                                                  ""
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      return [
                                                                                        _c(
                                                                                          "v-progress-linear",
                                                                                          _vm._g(
                                                                                            {
                                                                                              style:
                                                                                                "width: " +
                                                                                                _vm
                                                                                                  .calendar
                                                                                                  .selectedEvent
                                                                                                  .mailing
                                                                                                  .winningDistribution +
                                                                                                "%",
                                                                                              attrs: {
                                                                                                striped:
                                                                                                  "",
                                                                                                height:
                                                                                                  "20",
                                                                                                "buffer-value":
                                                                                                  "100",
                                                                                                color:
                                                                                                  _vm.$_splitMixin_winningColor
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "default",
                                                                                                    fn: function(
                                                                                                      ref
                                                                                                    ) {
                                                                                                      var value =
                                                                                                        ref.value
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "strong",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                _vm
                                                                                                                  .calendar
                                                                                                                  .selectedEvent
                                                                                                                  .mailing
                                                                                                                  .winningDistribution
                                                                                                              ) +
                                                                                                                "%"
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ]
                                                                                                    }
                                                                                                  }
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              )
                                                                                            },
                                                                                            on
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                false,
                                                                                343345359
                                                                              )
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$_splitMixin_winningCriteriaSelect.find(
                                                                                        function(
                                                                                          x
                                                                                        ) {
                                                                                          return (
                                                                                            x.value ===
                                                                                            _vm
                                                                                              .calendar
                                                                                              .selectedEvent
                                                                                              .mailing
                                                                                              .winningCriteria
                                                                                          )
                                                                                        }
                                                                                      )
                                                                                        ? _vm.$_splitMixin_winningCriteriaSelect.find(
                                                                                            function(
                                                                                              x
                                                                                            ) {
                                                                                              return (
                                                                                                x.value ===
                                                                                                _vm
                                                                                                  .calendar
                                                                                                  .selectedEvent
                                                                                                  .mailing
                                                                                                  .winningCriteria
                                                                                              )
                                                                                            }
                                                                                          )
                                                                                            .text
                                                                                        : "TBD"
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        2
                                                                      ),
                                                                  _vm.calendar
                                                                    .selectedEvent
                                                                    .mailing
                                                                    .modifiedOnLocal
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              Modified: " +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "displayDateTimeFormat"
                                                                                )(
                                                                                  _vm
                                                                                    .calendar
                                                                                    .selectedEvent
                                                                                    .mailing
                                                                                    .modifiedOnLocal
                                                                                )
                                                                              ) +
                                                                              " by " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .calendar
                                                                                  .selectedEvent
                                                                                  .mailing
                                                                                  .modifiedBy
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.calendar
                                                                    .selectedEvent
                                                                    .mailing
                                                                    .winningCriteria
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              Winning Criteria: " +
                                                                              _vm._s(
                                                                                _vm.$_splitMixin_winningCriteriaSelect.find(
                                                                                  function(
                                                                                    x
                                                                                  ) {
                                                                                    return (
                                                                                      x.value ===
                                                                                      _vm
                                                                                        .calendar
                                                                                        .selectedEvent
                                                                                        .mailing
                                                                                        .winningCriteria
                                                                                    )
                                                                                  }
                                                                                )
                                                                                  .text +
                                                                                  " (" +
                                                                                  _vm
                                                                                    .calendar
                                                                                    .selectedEvent
                                                                                    .mailing
                                                                                    .winningDistribution +
                                                                                  "%)"
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.calendar
                                                                    .selectedEvent
                                                                    .mailing
                                                                    .resendType
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              Resend Type: " +
                                                                              _vm._s(
                                                                                _vm.$_splitMixin_resendTypeSelect.find(
                                                                                  function(
                                                                                    x
                                                                                  ) {
                                                                                    return (
                                                                                      x.value ===
                                                                                      _vm
                                                                                        .calendar
                                                                                        .selectedEvent
                                                                                        .mailing
                                                                                        .resendType
                                                                                    )
                                                                                  }
                                                                                )
                                                                                  .text
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pb-2"
                                                                    },
                                                                    [
                                                                      _vm._l(
                                                                        _vm
                                                                          .calendar
                                                                          .selectedEvent
                                                                          .mailing
                                                                          .splits,
                                                                        function(
                                                                          split,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "v-btn",
                                                                            {
                                                                              key:
                                                                                split.id,
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                color:
                                                                                  _vm
                                                                                    .$_splitMixin_splitColors[
                                                                                    i
                                                                                  ]
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.getSplitHtmlDialog(
                                                                                    split.id,
                                                                                    _vm
                                                                                      .calendar
                                                                                      .selectedEvent
                                                                                      .mailing
                                                                                      .id
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "title"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm.$_splitMixin_indexToChar(
                                                                                          i
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      _vm
                                                                        .calendar
                                                                        .selectedEvent
                                                                        .mailing
                                                                        .scheduleDate &&
                                                                      _vm
                                                                        .calendar
                                                                        .selectedEvent
                                                                        .mailing
                                                                        .isScheduledStatus
                                                                        ? _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "ml-4",
                                                                              attrs: {
                                                                                color: _vm.$_dashMixin_scheduleDateColor(
                                                                                  _vm.$_dashMixin_dateToLocal(
                                                                                    _vm
                                                                                      .calendar
                                                                                      .selectedEvent
                                                                                      .mailing
                                                                                      .scheduleDate
                                                                                  ),
                                                                                  _vm
                                                                                    .calendar
                                                                                    .selectedEvent
                                                                                    .mailing,
                                                                                  _vm
                                                                                    .calendar
                                                                                    .selectedEvent
                                                                                    .campaign
                                                                                ),
                                                                                dark:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "displayDateTimeFormat"
                                                                                    )(
                                                                                      _vm.$_dashMixin_dateToLocal(
                                                                                        _vm
                                                                                          .calendar
                                                                                          .selectedEvent
                                                                                          .mailing
                                                                                          .scheduleDate
                                                                                      )
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .calendar
                                                                              .selectedEvent
                                                                              .mailing
                                                                              .winningDistribution >
                                                                              0 &&
                                                                              _vm
                                                                                .calendar
                                                                                .selectedEvent
                                                                                .mailing
                                                                                .winningScheduleDate &&
                                                                              _vm
                                                                                .calendar
                                                                                .selectedEvent
                                                                                .mailing
                                                                                .isScheduledStatus
                                                                              ? " / "
                                                                              : ""
                                                                          ) +
                                                                          "\n                              "
                                                                      ),
                                                                      _vm
                                                                        .calendar
                                                                        .selectedEvent
                                                                        .mailing
                                                                        .winningDistribution >
                                                                        0 &&
                                                                      _vm
                                                                        .calendar
                                                                        .selectedEvent
                                                                        .mailing
                                                                        .winningScheduleDate &&
                                                                      _vm
                                                                        .calendar
                                                                        .selectedEvent
                                                                        .mailing
                                                                        .isScheduledStatus
                                                                        ? _c(
                                                                            "v-chip",
                                                                            {
                                                                              attrs: {
                                                                                color: _vm.$_dashMixin_scheduleDateColorWinner(
                                                                                  _vm.$_dashMixin_dateToLocal(
                                                                                    _vm
                                                                                      .calendar
                                                                                      .selectedEvent
                                                                                      .mailing
                                                                                      .winningScheduleDate
                                                                                  ),
                                                                                  _vm
                                                                                    .calendar
                                                                                    .selectedEvent
                                                                                    .mailing,
                                                                                  _vm
                                                                                    .calendar
                                                                                    .selectedEvent
                                                                                    .campaign
                                                                                ),
                                                                                dark:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "displayDateTimeFormat"
                                                                                    )(
                                                                                      _vm.$_dashMixin_dateToLocal(
                                                                                        _vm
                                                                                          .calendar
                                                                                          .selectedEvent
                                                                                          .mailing
                                                                                          .winningScheduleDate
                                                                                      )
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    2
                                                                  ),
                                                                  _c(
                                                                    "v-divider"
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "6"
                                                                  }
                                                                },
                                                                [
                                                                  _vm.calendar
                                                                    .selectedEvent
                                                                    .targetAudience
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm
                                                                            .calendar
                                                                            .selectedEvent
                                                                            .targetAudience
                                                                            .status
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "pb-1"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "font-weight-bold"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Target Audience: "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mr-2",
                                                                                                      attrs: {
                                                                                                        color: _vm.$_dashMixin_taStatusColor(
                                                                                                          _vm
                                                                                                            .calendar
                                                                                                            .selectedEvent
                                                                                                            .targetAudience
                                                                                                            .status
                                                                                                        )
                                                                                                      }
                                                                                                    },
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                      " +
                                                                                                        _vm._s(
                                                                                                          _vm.$_dashMixin_getTAIcon(
                                                                                                            _vm
                                                                                                              .calendar
                                                                                                              .selectedEvent
                                                                                                              .targetAudience
                                                                                                              .status
                                                                                                          )
                                                                                                        ) +
                                                                                                        "\n                                    "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        false,
                                                                                        883448204
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm
                                                                                                .calendar
                                                                                                .selectedEvent
                                                                                                .targetAudience
                                                                                                .statusDisplay ||
                                                                                                "Not selected"
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  !_vm
                                                                                    .calendar
                                                                                    .selectedEvent
                                                                                    .campaign
                                                                                    .readOnlyActions &&
                                                                                  _vm.showTAActions
                                                                                    ? _c(
                                                                                        "span",
                                                                                        [
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                top:
                                                                                                  ""
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "activator",
                                                                                                    fn: function(
                                                                                                      ref
                                                                                                    ) {
                                                                                                      var on =
                                                                                                        ref.on
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          _vm._g(
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "mr-2",
                                                                                                              attrs: {
                                                                                                                color:
                                                                                                                  _vm
                                                                                                                    .calendar
                                                                                                                    .selectedEvent
                                                                                                                    .targetAudience
                                                                                                                    .status ===
                                                                                                                  _vm.$_taStatus_Draft
                                                                                                                    ? "success"
                                                                                                                    : "info"
                                                                                                              },
                                                                                                              on: {
                                                                                                                click: function(
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  return _vm.$_dashMixin_nowwEditLink(
                                                                                                                    _vm
                                                                                                                      .calendar
                                                                                                                      .selectedEvent
                                                                                                                      .targetAudience
                                                                                                                  )
                                                                                                                }
                                                                                                              }
                                                                                                            },
                                                                                                            on
                                                                                                          ),
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "\n                                        " +
                                                                                                                _vm._s(
                                                                                                                  _vm.$_dashMixin_getTAIcon(
                                                                                                                    _vm
                                                                                                                      .calendar
                                                                                                                      .selectedEvent
                                                                                                                      .targetAudience
                                                                                                                      .status
                                                                                                                  )
                                                                                                                ) +
                                                                                                                "\n                                      "
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ]
                                                                                                    }
                                                                                                  }
                                                                                                ],
                                                                                                null,
                                                                                                false,
                                                                                                2199475560
                                                                                              )
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .calendar
                                                                                                        .selectedEvent
                                                                                                        .targetAudience
                                                                                                        .status ===
                                                                                                        _vm.$_taStatus_Draft
                                                                                                        ? "Edit"
                                                                                                        : "View"
                                                                                                    ) +
                                                                                                      " Target Audience"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                top:
                                                                                                  ""
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "activator",
                                                                                                    fn: function(
                                                                                                      ref
                                                                                                    ) {
                                                                                                      var on =
                                                                                                        ref.on
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          _vm._g(
                                                                                                            {
                                                                                                              attrs: {
                                                                                                                color:
                                                                                                                  "info"
                                                                                                              },
                                                                                                              on: {
                                                                                                                click: function(
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  return _vm.$_dashMixin_nowwCopyLink(
                                                                                                                    _vm
                                                                                                                      .calendar
                                                                                                                      .selectedEvent
                                                                                                                      .targetAudience
                                                                                                                  )
                                                                                                                }
                                                                                                              }
                                                                                                            },
                                                                                                            on
                                                                                                          ),
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "\n                                        mdi-content-copy\n                                      "
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ]
                                                                                                    }
                                                                                                  }
                                                                                                ],
                                                                                                null,
                                                                                                false,
                                                                                                2147778403
                                                                                              )
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Copy Target Audience"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm
                                                                            .calendar
                                                                            .selectedEvent
                                                                            .targetAudience
                                                                            .jobNumber
                                                                            ? _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .calendar
                                                                                          .selectedEvent
                                                                                          .targetAudience
                                                                                          .jobNumber
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm
                                                                            .calendar
                                                                            .selectedEvent
                                                                            .targetAudience
                                                                            .poNumber
                                                                            ? _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .calendar
                                                                                          .selectedEvent
                                                                                          .targetAudience
                                                                                          .poNumber
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm
                                                                            .calendar
                                                                            .selectedEvent
                                                                            .targetAudience
                                                                            .contactName
                                                                            ? _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                Contact: " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .calendar
                                                                                          .selectedEvent
                                                                                          .targetAudience
                                                                                          .contactName
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm
                                                                            .calendar
                                                                            .selectedEvent
                                                                            .targetAudience
                                                                            .orderDateLocal
                                                                            ? _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                Order Date: " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "displayDateFormat"
                                                                                        )(
                                                                                          _vm
                                                                                            .calendar
                                                                                            .selectedEvent
                                                                                            .targetAudience
                                                                                            .orderDateLocal
                                                                                        )
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm
                                                                            .calendar
                                                                            .selectedEvent
                                                                            .targetAudience
                                                                            .fileType
                                                                            ? _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "v-chip",
                                                                                    {
                                                                                      attrs: {
                                                                                        color: _vm.$_dashMixin_fileTypeColor(
                                                                                          _vm
                                                                                            .calendar
                                                                                            .selectedEvent
                                                                                            .targetAudience
                                                                                            .fileType
                                                                                        ),
                                                                                        dark: _vm.$_dashMixin_fileTypeColorDark(
                                                                                          _vm
                                                                                            .calendar
                                                                                            .selectedEvent
                                                                                            .targetAudience
                                                                                            .fileType
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                  " +
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .calendar
                                                                                              .selectedEvent
                                                                                              .targetAudience
                                                                                              .fileType
                                                                                          ) +
                                                                                          "\n                                "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm
                                                                            .calendar
                                                                            .selectedEvent
                                                                            .targetAudience
                                                                            .count
                                                                            ? _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                Count:\n                                "
                                                                                  ),
                                                                                  !_vm
                                                                                    .calendar
                                                                                    .selectedEvent
                                                                                    .targetAudience
                                                                                    .nthRecordSubCount
                                                                                    ? _c(
                                                                                        "v-tooltip",
                                                                                        {
                                                                                          attrs: {
                                                                                            top:
                                                                                              ""
                                                                                          },
                                                                                          scopedSlots: _vm._u(
                                                                                            [
                                                                                              {
                                                                                                key:
                                                                                                  "activator",
                                                                                                fn: function(
                                                                                                  ref
                                                                                                ) {
                                                                                                  var on =
                                                                                                    ref.on
                                                                                                  return [
                                                                                                    _c(
                                                                                                      "v-chip",
                                                                                                      _vm._g(
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            color: _vm.$_dashMixin_countColor(
                                                                                                              _vm
                                                                                                                .calendar
                                                                                                                .selectedEvent
                                                                                                                .targetAudience
                                                                                                                .count
                                                                                                            ),
                                                                                                            dark:
                                                                                                              ""
                                                                                                          }
                                                                                                        },
                                                                                                        on
                                                                                                      ),
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                      " +
                                                                                                            _vm._s(
                                                                                                              _vm._f(
                                                                                                                "commaNumber"
                                                                                                              )(
                                                                                                                _vm
                                                                                                                  .calendar
                                                                                                                  .selectedEvent
                                                                                                                  .targetAudience
                                                                                                                  .count
                                                                                                              )
                                                                                                            ) +
                                                                                                            "\n                                    "
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ]
                                                                                                }
                                                                                              }
                                                                                            ],
                                                                                            null,
                                                                                            false,
                                                                                            762224034
                                                                                          )
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.$_dashMixin_countSendDaysLabel(
                                                                                                    _vm
                                                                                                      .calendar
                                                                                                      .selectedEvent
                                                                                                      .targetAudience
                                                                                                      .count
                                                                                                  )
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _c(
                                                                                        "v-tooltip",
                                                                                        {
                                                                                          attrs: {
                                                                                            top:
                                                                                              ""
                                                                                          },
                                                                                          scopedSlots: _vm._u(
                                                                                            [
                                                                                              {
                                                                                                key:
                                                                                                  "activator",
                                                                                                fn: function(
                                                                                                  ref
                                                                                                ) {
                                                                                                  var on =
                                                                                                    ref.on
                                                                                                  return [
                                                                                                    _c(
                                                                                                      "v-chip",
                                                                                                      _vm._g(
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            color: _vm.$_dashMixin_countColor(
                                                                                                              _vm
                                                                                                                .calendar
                                                                                                                .selectedEvent
                                                                                                                .targetAudience
                                                                                                                .nthRecordSubCount
                                                                                                            ),
                                                                                                            dark:
                                                                                                              ""
                                                                                                          }
                                                                                                        },
                                                                                                        on
                                                                                                      ),
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                      " +
                                                                                                            _vm._s(
                                                                                                              _vm._f(
                                                                                                                "commaNumber"
                                                                                                              )(
                                                                                                                _vm
                                                                                                                  .calendar
                                                                                                                  .selectedEvent
                                                                                                                  .targetAudience
                                                                                                                  .nthRecordSubCount
                                                                                                              )
                                                                                                            ) +
                                                                                                            "/" +
                                                                                                            _vm._s(
                                                                                                              _vm._f(
                                                                                                                "commaNumber"
                                                                                                              )(
                                                                                                                _vm
                                                                                                                  .calendar
                                                                                                                  .selectedEvent
                                                                                                                  .targetAudience
                                                                                                                  .count
                                                                                                              )
                                                                                                            ) +
                                                                                                            "\n                                    "
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ]
                                                                                                }
                                                                                              }
                                                                                            ],
                                                                                            null,
                                                                                            false,
                                                                                            2010629448
                                                                                          )
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm._f(
                                                                                                    "commaNumber"
                                                                                                  )(
                                                                                                    _vm
                                                                                                      .calendar
                                                                                                      .selectedEvent
                                                                                                      .targetAudience
                                                                                                      .nthRecordSubCount
                                                                                                  )
                                                                                                ) +
                                                                                                  " Nth record of " +
                                                                                                  _vm._s(
                                                                                                    _vm._f(
                                                                                                      "commaNumber"
                                                                                                    )(
                                                                                                      _vm
                                                                                                        .calendar
                                                                                                        .selectedEvent
                                                                                                        .targetAudience
                                                                                                        .count
                                                                                                    )
                                                                                                  ) +
                                                                                                  " count. " +
                                                                                                  _vm._s(
                                                                                                    _vm.$_dashMixin_countSendDaysLabel(
                                                                                                      _vm
                                                                                                        .calendar
                                                                                                        .selectedEvent
                                                                                                        .targetAudience
                                                                                                        .nthRecordSubCount
                                                                                                    )
                                                                                                  )
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              ),
                                                              _c("v-divider", {
                                                                staticClass:
                                                                  "mx-n1",
                                                                attrs: {
                                                                  vertical: ""
                                                                }
                                                              }),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "6"
                                                                  }
                                                                },
                                                                [
                                                                  _vm.calendar
                                                                    .selectedEvent
                                                                    .campaign
                                                                    .campaignStatus
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-bold"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Campaign: "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "mr-2",
                                                                              attrs: {
                                                                                color: _vm.$_dashMixin_campaignStatusColor(
                                                                                  _vm
                                                                                    .calendar
                                                                                    .selectedEvent
                                                                                    .campaign
                                                                                ),
                                                                                light:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .calendar
                                                                                      .selectedEvent
                                                                                      .campaign
                                                                                      .campaignStatusDisplay
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          !_vm
                                                                            .calendar
                                                                            .selectedEvent
                                                                            .campaign
                                                                            .readOnlyActions
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mr-2",
                                                                                                      attrs: {
                                                                                                        disabled: !_vm
                                                                                                          .calendar
                                                                                                          .selectedEvent
                                                                                                          .campaign
                                                                                                          .canAddMailings,
                                                                                                        color:
                                                                                                          "success"
                                                                                                      },
                                                                                                      on: {
                                                                                                        click: function(
                                                                                                          $event
                                                                                                        ) {
                                                                                                          return _vm.$_dashMixin_addMailing(
                                                                                                            _vm
                                                                                                              .calendar
                                                                                                              .selectedEvent
                                                                                                              .campaign
                                                                                                          )
                                                                                                        }
                                                                                                      }
                                                                                                    },
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                      mdi-email-plus\n                                    "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        false,
                                                                                        1447282260
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Add Mailing"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-menu",
                                                                                    {
                                                                                      attrs: {
                                                                                        right:
                                                                                          "",
                                                                                        "x-offset":
                                                                                          "",
                                                                                        origin:
                                                                                          "right",
                                                                                        transition:
                                                                                          "slide-x-transition"
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var onMenu =
                                                                                                ref.on
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-tooltip",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      top:
                                                                                                        ""
                                                                                                    },
                                                                                                    scopedSlots: _vm._u(
                                                                                                      [
                                                                                                        {
                                                                                                          key:
                                                                                                            "activator",
                                                                                                          fn: function(
                                                                                                            ref
                                                                                                          ) {
                                                                                                            var onTooltip =
                                                                                                              ref.on
                                                                                                            return [
                                                                                                              _c(
                                                                                                                "v-icon",
                                                                                                                _vm._g(
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "mr-2",
                                                                                                                    attrs: {
                                                                                                                      disabled:
                                                                                                                        _vm
                                                                                                                          .calendar
                                                                                                                          .selectedEvent
                                                                                                                          .campaign
                                                                                                                          .campaignStatus !==
                                                                                                                        1,
                                                                                                                      color:
                                                                                                                        "error"
                                                                                                                    }
                                                                                                                  },
                                                                                                                  Object.assign(
                                                                                                                    {},
                                                                                                                    onMenu,
                                                                                                                    onTooltip
                                                                                                                  )
                                                                                                                ),
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "\n                                          mdi-close\n                                        "
                                                                                                                  )
                                                                                                                ]
                                                                                                              )
                                                                                                            ]
                                                                                                          }
                                                                                                        }
                                                                                                      ],
                                                                                                      null,
                                                                                                      true
                                                                                                    )
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "Cancel Campaign"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        false,
                                                                                        1928085318
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-card",
                                                                                        [
                                                                                          _c(
                                                                                            "v-card-text",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pa-5"
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Are you sure you want to cancel this campaign? No scheduled mailings will be sent."
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "v-divider"
                                                                                          ),
                                                                                          _c(
                                                                                            "v-card-actions",
                                                                                            [
                                                                                              _c(
                                                                                                "v-spacer"
                                                                                              ),
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    color:
                                                                                                      "tertiary",
                                                                                                    disabled: _vm.isLoading()
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                        Cancel\n                                      "
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    color:
                                                                                                      "error",
                                                                                                    loading: _vm.isLoading()
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      _vm.calendar.selectedEvent.campaign = _vm.$_dashMixin_cancelCampaign(
                                                                                                        _vm
                                                                                                          .calendar
                                                                                                          .selectedEvent
                                                                                                          .campaign
                                                                                                      )
                                                                                                    }
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                        Yes\n                                      "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mr-2",
                                                                                                      attrs: {
                                                                                                        color:
                                                                                                          "info"
                                                                                                      },
                                                                                                      on: {
                                                                                                        click: function(
                                                                                                          $event
                                                                                                        ) {
                                                                                                          return _vm.copyCampaign(
                                                                                                            _vm
                                                                                                              .calendar
                                                                                                              .selectedEvent
                                                                                                              .campaign
                                                                                                          )
                                                                                                        }
                                                                                                      }
                                                                                                    },
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                      mdi-content-copy\n                                    "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        false,
                                                                                        4099596294
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Copy Campaign"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                top:
                                                                                  ""
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      return [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          _vm._g(
                                                                                            {
                                                                                              attrs: {
                                                                                                color:
                                                                                                  "info"
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.$_dashMixin_campaignHistory(
                                                                                                    _vm
                                                                                                      .calendar
                                                                                                      .selectedEvent
                                                                                                      .campaign
                                                                                                      .campaignId
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                    mdi-book-open-page-variant\n                                  "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                false,
                                                                                2340384936
                                                                              )
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Campaign History"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm
                                                                            .calendar
                                                                            .selectedEvent
                                                                            .campaign
                                                                            .campaignStatus ===
                                                                            _vm.$_campaignStatus_Completed &&
                                                                          _vm
                                                                            .calendar
                                                                            .selectedEvent
                                                                            .campaign
                                                                            .campaignFinished &&
                                                                          (_vm.$_global_hasRouteClaims(
                                                                            "CampaignEventsHeatmap"
                                                                          ) ||
                                                                            _vm.$_global_hasRouteClaims(
                                                                              "CampaignReport"
                                                                            ))
                                                                            ? _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs: {
                                                                                    top:
                                                                                      ""
                                                                                  },
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key:
                                                                                          "activator",
                                                                                        fn: function(
                                                                                          ref
                                                                                        ) {
                                                                                          var onTooltip =
                                                                                            ref.on
                                                                                          return [
                                                                                            _c(
                                                                                              "v-menu",
                                                                                              {
                                                                                                attrs: {
                                                                                                  "offset-y":
                                                                                                    ""
                                                                                                },
                                                                                                scopedSlots: _vm._u(
                                                                                                  [
                                                                                                    {
                                                                                                      key:
                                                                                                        "activator",
                                                                                                      fn: function(
                                                                                                        ref
                                                                                                      ) {
                                                                                                        var onMenu =
                                                                                                          ref.on
                                                                                                        var attrs =
                                                                                                          ref.attrs
                                                                                                        return [
                                                                                                          _c(
                                                                                                            "v-icon",
                                                                                                            _vm._g(
                                                                                                              _vm._b(
                                                                                                                {
                                                                                                                  attrs: {
                                                                                                                    color:
                                                                                                                      "info"
                                                                                                                  }
                                                                                                                },
                                                                                                                "v-icon",
                                                                                                                attrs,
                                                                                                                false
                                                                                                              ),
                                                                                                              Object.assign(
                                                                                                                {},
                                                                                                                onTooltip,
                                                                                                                onMenu
                                                                                                              )
                                                                                                            ),
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                        mdi-chart-box\n                                      "
                                                                                                              )
                                                                                                            ]
                                                                                                          )
                                                                                                        ]
                                                                                                      }
                                                                                                    }
                                                                                                  ],
                                                                                                  null,
                                                                                                  true
                                                                                                )
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "v-list",
                                                                                                  [
                                                                                                    _vm.$_global_hasRouteClaims(
                                                                                                      "CampaignEventsHeatmap"
                                                                                                    ) &&
                                                                                                    _vm.$_dashMixin_isGeoDataFileTypeId(
                                                                                                      _vm
                                                                                                        .calendar
                                                                                                        .selectedEvent
                                                                                                        .campaign
                                                                                                        .targetAudienceFileTypeId
                                                                                                    )
                                                                                                      ? _c(
                                                                                                          "v-list-item",
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-list-item-title",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "router-link",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      to: {
                                                                                                                        name:
                                                                                                                          "CampaignEventsHeatmap",
                                                                                                                        params: {
                                                                                                                          campaignId:
                                                                                                                            _vm
                                                                                                                              .calendar
                                                                                                                              .selectedEvent
                                                                                                                              .campaign
                                                                                                                              .campaignId
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "\n                                            Campaign Heatmap\n                                          "
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      : _vm._e(),
                                                                                                    _vm.$_global_hasRouteClaims(
                                                                                                      "CampaignReport"
                                                                                                    )
                                                                                                      ? _c(
                                                                                                          "v-list-item",
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-list-item-title",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "router-link",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      to: {
                                                                                                                        name:
                                                                                                                          "CampaignReport",
                                                                                                                        params: {
                                                                                                                          campaignId:
                                                                                                                            _vm
                                                                                                                              .calendar
                                                                                                                              .selectedEvent
                                                                                                                              .campaign
                                                                                                                              .campaignId
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "\n                                            Campaign Report\n                                          "
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      : _vm._e()
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          ]
                                                                                        }
                                                                                      }
                                                                                    ],
                                                                                    null,
                                                                                    false,
                                                                                    1140119210
                                                                                  )
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Reporting"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e()
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.calendar
                                                                    .selectedEvent
                                                                    .campaign
                                                                    .campaignName
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .calendar
                                                                                  .selectedEvent
                                                                                  .campaign
                                                                                  .campaignName
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.calendar
                                                                    .selectedEvent
                                                                    .campaign
                                                                    .onBehalfOfName
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              Contact: " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .calendar
                                                                                  .selectedEvent
                                                                                  .campaign
                                                                                  .onBehalfOfName
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.calendar
                                                                    .selectedEvent
                                                                    .campaign
                                                                    .modifiedBy
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              Modified by: " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .calendar
                                                                                  .selectedEvent
                                                                                  .campaign
                                                                                  .modifiedBy
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      staticClass:
                                                                        "pa-2",
                                                                      attrs: {
                                                                        justify:
                                                                          "center",
                                                                        align:
                                                                          "start"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._l(
                                                                        _vm
                                                                          .calendar
                                                                          .selectedEvent
                                                                          .mailings,
                                                                        function(
                                                                          mailing,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "v-col",
                                                                            {
                                                                              key:
                                                                                mailing.id,
                                                                              staticClass:
                                                                                "px-0 pb-0",
                                                                              attrs: {
                                                                                cols:
                                                                                  "auto"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs: {
                                                                                    top:
                                                                                      ""
                                                                                  },
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key:
                                                                                          "activator",
                                                                                        fn: function(
                                                                                          ref
                                                                                        ) {
                                                                                          var on =
                                                                                            ref.on
                                                                                          return [
                                                                                            mailing.id ===
                                                                                            _vm
                                                                                              .calendar
                                                                                              .selectedEvent
                                                                                              .mailing
                                                                                              .id
                                                                                              ? _c(
                                                                                                  "v-chip",
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      _vm._g(
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            color: _vm.$_dashMixin_getMailingIconColor(
                                                                                                              mailing
                                                                                                            ),
                                                                                                            large:
                                                                                                              ""
                                                                                                          }
                                                                                                        },
                                                                                                        on
                                                                                                      ),
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                        " +
                                                                                                            _vm._s(
                                                                                                              _vm.$_dashMixin_getMailingIcon(
                                                                                                                mailing
                                                                                                              )
                                                                                                            ) +
                                                                                                            "\n                                      "
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              : _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "ma-1",
                                                                                                      attrs: {
                                                                                                        color: _vm.$_dashMixin_getMailingIconColor(
                                                                                                          mailing
                                                                                                        )
                                                                                                      }
                                                                                                    },
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                      " +
                                                                                                        _vm._s(
                                                                                                          _vm.$_dashMixin_getMailingIcon(
                                                                                                            mailing
                                                                                                          )
                                                                                                        ) +
                                                                                                        "\n                                    "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                          ]
                                                                                        }
                                                                                      }
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  )
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          mailing.statusDisplay
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              !_vm
                                                                                .calendar
                                                                                .selectedEvent
                                                                                .campaign
                                                                                .readOnlyActions
                                                                                ? [
                                                                                    !mailing.isScheduledStatus
                                                                                      ? [
                                                                                          _c(
                                                                                            "br"
                                                                                          ),
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                top:
                                                                                                  ""
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "activator",
                                                                                                    fn: function(
                                                                                                      ref
                                                                                                    ) {
                                                                                                      var on =
                                                                                                        ref.on
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          _vm._g(
                                                                                                            {
                                                                                                              class:
                                                                                                                "my-1 mx-" +
                                                                                                                (mailing.id ===
                                                                                                                _vm
                                                                                                                  .calendar
                                                                                                                  .selectedEvent
                                                                                                                  .mailing
                                                                                                                  .id
                                                                                                                  ? "4"
                                                                                                                  : "1"),
                                                                                                              attrs: {
                                                                                                                disabled:
                                                                                                                  _vm
                                                                                                                    .calendar
                                                                                                                    .selectedEvent
                                                                                                                    .campaign
                                                                                                                    .isCanceled,
                                                                                                                color:
                                                                                                                  "success"
                                                                                                              },
                                                                                                              on: {
                                                                                                                click: function(
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  _vm.calendar.selectedOpen = false
                                                                                                                  _vm.$_dashMixin_continueMailing(
                                                                                                                    mailing
                                                                                                                  )
                                                                                                                }
                                                                                                              }
                                                                                                            },
                                                                                                            on
                                                                                                          ),
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "\n                                          mdi-pencil\n                                        "
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ]
                                                                                                    }
                                                                                                  }
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              )
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Continue Mailing"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          mailing.sequence >
                                                                                          1
                                                                                            ? _c(
                                                                                                "br"
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          mailing.sequence >
                                                                                          1
                                                                                            ? _c(
                                                                                                "v-menu",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    right:
                                                                                                      "",
                                                                                                    "x-offset":
                                                                                                      "",
                                                                                                    origin:
                                                                                                      "right",
                                                                                                    transition:
                                                                                                      "slide-x-transition"
                                                                                                  },
                                                                                                  scopedSlots: _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key:
                                                                                                          "activator",
                                                                                                        fn: function(
                                                                                                          ref
                                                                                                        ) {
                                                                                                          var onMenu =
                                                                                                            ref.on
                                                                                                          return [
                                                                                                            _c(
                                                                                                              "v-tooltip",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  top:
                                                                                                                    ""
                                                                                                                },
                                                                                                                scopedSlots: _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "activator",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var onTooltip =
                                                                                                                          ref.on
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "v-icon",
                                                                                                                            _vm._g(
                                                                                                                              {
                                                                                                                                class:
                                                                                                                                  "my-1 mx-" +
                                                                                                                                  (mailing.id ===
                                                                                                                                  _vm
                                                                                                                                    .calendar
                                                                                                                                    .selectedEvent
                                                                                                                                    .mailing
                                                                                                                                    .id
                                                                                                                                    ? "4"
                                                                                                                                    : "1"),
                                                                                                                                attrs: {
                                                                                                                                  disabled:
                                                                                                                                    _vm
                                                                                                                                      .calendar
                                                                                                                                      .selectedEvent
                                                                                                                                      .campaign
                                                                                                                                      .isCanceled,
                                                                                                                                  color:
                                                                                                                                    "error"
                                                                                                                                }
                                                                                                                              },
                                                                                                                              Object.assign(
                                                                                                                                {},
                                                                                                                                onMenu,
                                                                                                                                onTooltip
                                                                                                                              )
                                                                                                                            ),
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                "\n                                              mdi-close\n                                            "
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    }
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                )
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "span",
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "Delete Resend"
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ]
                                                                                                        }
                                                                                                      }
                                                                                                    ],
                                                                                                    null,
                                                                                                    true
                                                                                                  )
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-card",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-card-text",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "pa-5"
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "span",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "Are you sure you want to delete this resend?"
                                                                                                              )
                                                                                                            ]
                                                                                                          )
                                                                                                        ]
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-divider"
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-card-actions",
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-spacer"
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "v-btn",
                                                                                                            {
                                                                                                              attrs: {
                                                                                                                color:
                                                                                                                  "tertiary",
                                                                                                                disabled: _vm.isLoading()
                                                                                                              }
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                            Cancel\n                                          "
                                                                                                              )
                                                                                                            ]
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "v-btn",
                                                                                                            {
                                                                                                              attrs: {
                                                                                                                color:
                                                                                                                  "error",
                                                                                                                loading: _vm.isLoading()
                                                                                                              },
                                                                                                              on: {
                                                                                                                click: function(
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  return _vm.deleteResend(
                                                                                                                    _vm
                                                                                                                      .calendar
                                                                                                                      .selectedEvent,
                                                                                                                    mailing,
                                                                                                                    index
                                                                                                                  )
                                                                                                                }
                                                                                                              }
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                            Yes\n                                          "
                                                                                                              )
                                                                                                            ]
                                                                                                          )
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ]
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "br"
                                                                                    ),
                                                                                    _c(
                                                                                      "v-tooltip",
                                                                                      {
                                                                                        attrs: {
                                                                                          top:
                                                                                            ""
                                                                                        },
                                                                                        scopedSlots: _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key:
                                                                                                "activator",
                                                                                              fn: function(
                                                                                                ref
                                                                                              ) {
                                                                                                var on =
                                                                                                  ref.on
                                                                                                return [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    _vm._g(
                                                                                                      {
                                                                                                        class:
                                                                                                          "my-1 mx-" +
                                                                                                          (mailing.id ===
                                                                                                          _vm
                                                                                                            .calendar
                                                                                                            .selectedEvent
                                                                                                            .mailing
                                                                                                            .id
                                                                                                            ? "4"
                                                                                                            : "1"),
                                                                                                        attrs: {
                                                                                                          disabled: !_vm
                                                                                                            .calendar
                                                                                                            .selectedEvent
                                                                                                            .campaign
                                                                                                            .canAddMailings,
                                                                                                          color:
                                                                                                            "info"
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            _vm.calendar.selectedOpen = false
                                                                                                            _vm.$_dashMixin_copyMailing(
                                                                                                              mailing
                                                                                                            )
                                                                                                          }
                                                                                                        }
                                                                                                      },
                                                                                                      on
                                                                                                    ),
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                        mdi-content-copy\n                                      "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              }
                                                                                            }
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        )
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Add Copy of Mailing"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    mailing.isScheduledStatus
                                                                                      ? _c(
                                                                                          "br"
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    mailing.isScheduledStatus
                                                                                      ? [
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                top:
                                                                                                  ""
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "activator",
                                                                                                    fn: function(
                                                                                                      ref
                                                                                                    ) {
                                                                                                      var on =
                                                                                                        ref.on
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          _vm._g(
                                                                                                            {
                                                                                                              class:
                                                                                                                "my-1 mx-" +
                                                                                                                (mailing.id ===
                                                                                                                _vm
                                                                                                                  .calendar
                                                                                                                  .selectedEvent
                                                                                                                  .mailing
                                                                                                                  .id
                                                                                                                  ? "4"
                                                                                                                  : "1"),
                                                                                                              attrs: {
                                                                                                                disabled:
                                                                                                                  _vm
                                                                                                                    .calendar
                                                                                                                    .selectedEvent
                                                                                                                    .campaign
                                                                                                                    .isCanceled ||
                                                                                                                  mailing.status ===
                                                                                                                    _vm.$_mailingItemStatus_Sent ||
                                                                                                                  mailing.status ===
                                                                                                                    _vm.$_mailingItemStatus_Sending,
                                                                                                                color:
                                                                                                                  "warning"
                                                                                                              },
                                                                                                              on: {
                                                                                                                click: function(
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  _vm.calendar.selectedOpen = false
                                                                                                                  _vm.$_dashMixin_unscheduleMailing(
                                                                                                                    mailing
                                                                                                                  )
                                                                                                                }
                                                                                                              }
                                                                                                            },
                                                                                                            on
                                                                                                          ),
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "\n                                          mdi-calendar-edit\n                                        "
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ]
                                                                                                    }
                                                                                                  }
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              )
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Unschedule Mailing"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      : _vm._e()
                                                                                  ]
                                                                                : _vm._e(),
                                                                              mailing.isScheduledStatus &&
                                                                              (_vm.$_global_hasRouteClaims(
                                                                                "MailingResultsDashboard"
                                                                              ) ||
                                                                                (mailing.status ===
                                                                                  _vm.$_mailingItemStatus_Sent &&
                                                                                  _vm.$_global_hasRouteClaims(
                                                                                    "SingleMailingReport"
                                                                                  )))
                                                                                ? _c(
                                                                                    "br"
                                                                                  )
                                                                                : _vm._e(),
                                                                              mailing.isScheduledStatus &&
                                                                              (_vm.$_global_hasRouteClaims(
                                                                                "MailingResultsDashboard"
                                                                              ) ||
                                                                                (mailing.status ===
                                                                                  _vm.$_mailingItemStatus_Sent &&
                                                                                  _vm.$_global_hasRouteClaims(
                                                                                    "SingleMailingReport"
                                                                                  )))
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var onTooltip =
                                                                                                ref.on
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-menu",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      "offset-y":
                                                                                                        ""
                                                                                                    },
                                                                                                    scopedSlots: _vm._u(
                                                                                                      [
                                                                                                        {
                                                                                                          key:
                                                                                                            "activator",
                                                                                                          fn: function(
                                                                                                            ref
                                                                                                          ) {
                                                                                                            var onMenu =
                                                                                                              ref.on
                                                                                                            var attrs =
                                                                                                              ref.attrs
                                                                                                            return [
                                                                                                              _c(
                                                                                                                "v-icon",
                                                                                                                _vm._g(
                                                                                                                  _vm._b(
                                                                                                                    {
                                                                                                                      class:
                                                                                                                        "my-1 mx-" +
                                                                                                                        (mailing.id ===
                                                                                                                        _vm
                                                                                                                          .calendar
                                                                                                                          .selectedEvent
                                                                                                                          .mailing
                                                                                                                          .id
                                                                                                                          ? "4"
                                                                                                                          : "1"),
                                                                                                                      attrs: {
                                                                                                                        color:
                                                                                                                          "info"
                                                                                                                      }
                                                                                                                    },
                                                                                                                    "v-icon",
                                                                                                                    attrs,
                                                                                                                    false
                                                                                                                  ),
                                                                                                                  Object.assign(
                                                                                                                    {},
                                                                                                                    onTooltip,
                                                                                                                    onMenu
                                                                                                                  )
                                                                                                                ),
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "\n                                          mdi-chart-box\n                                        "
                                                                                                                  )
                                                                                                                ]
                                                                                                              )
                                                                                                            ]
                                                                                                          }
                                                                                                        }
                                                                                                      ],
                                                                                                      null,
                                                                                                      true
                                                                                                    )
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-list",
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-list-item",
                                                                                                          [
                                                                                                            _vm.$_global_hasRouteClaims(
                                                                                                              "MailingResultsDashboard"
                                                                                                            )
                                                                                                              ? _c(
                                                                                                                  "v-list-item-title",
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "router-link",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          to: {
                                                                                                                            name:
                                                                                                                              "MailingResultsDashboard",
                                                                                                                            params: {
                                                                                                                              campaignId:
                                                                                                                                _vm
                                                                                                                                  .calendar
                                                                                                                                  .selectedEvent
                                                                                                                                  .campaign
                                                                                                                                  .campaignId,
                                                                                                                              mailingItemId:
                                                                                                                                mailing.id
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "\n                                              Real Time Results\n                                            "
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              : _vm._e()
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        mailing.status ===
                                                                                                          _vm.$_mailingItemStatus_Sent &&
                                                                                                        _vm.$_global_hasRouteClaims(
                                                                                                          "SingleMailingReport"
                                                                                                        )
                                                                                                          ? _c(
                                                                                                              "v-list-item",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-list-item-title",
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "router-link",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          to: {
                                                                                                                            name:
                                                                                                                              "SingleMailingReport",
                                                                                                                            params: {
                                                                                                                              campaignId:
                                                                                                                                _vm
                                                                                                                                  .calendar
                                                                                                                                  .selectedEvent
                                                                                                                                  .campaign
                                                                                                                                  .campaignId,
                                                                                                                              mailingItemId:
                                                                                                                                mailing.id
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "\n                                              Single Mailing Report\n                                            "
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          : _vm._e()
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Reporting"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ],
                                                                            2
                                                                          )
                                                                        }
                                                                      ),
                                                                      _vm
                                                                        .calendar
                                                                        .selectedEvent
                                                                        .campaign
                                                                        .numberOfBroadcasts >=
                                                                        _vm
                                                                          .calendar
                                                                          .selectedEvent
                                                                          .mailings
                                                                          .length &&
                                                                      !_vm
                                                                        .calendar
                                                                        .selectedEvent
                                                                        .campaignExpired
                                                                        ? _vm._l(
                                                                            _vm
                                                                              .calendar
                                                                              .selectedEvent
                                                                              .campaign
                                                                              .numberOfBroadcasts -
                                                                              _vm
                                                                                .calendar
                                                                                .selectedEvent
                                                                                .mailings
                                                                                .length,
                                                                            function(
                                                                              i
                                                                            ) {
                                                                              return _c(
                                                                                "v-col",
                                                                                {
                                                                                  key: i,
                                                                                  staticClass:
                                                                                    "px-0",
                                                                                  attrs: {
                                                                                    cols:
                                                                                      "auto"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        top:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "ma-1",
                                                                                                      attrs: {
                                                                                                        color:
                                                                                                          "grey"
                                                                                                      }
                                                                                                    },
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                        mdi-email-outline\n                                      "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Not Created"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "v-col"
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "secondary"
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.closeCalendarEvent
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Close\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              "\n            Final Broadcast Contacts\n          "
                            )
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("ContactUploadTable", {
                                attrs: { "final-broadcast": "" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { color: "tertiary", disabled: _vm.isLoading() },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("\n          mdi-menu-left-outline\n        ")
                  ]),
                  _vm._v("Back\n      ")
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: {
                    "x-offset": "",
                    origin: "left",
                    transition: "slide-x-transition"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var onMenu = ref.on
                        return [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                color: "success",
                                disabled:
                                  !!_vm.requiredToScheduleMailingId ||
                                  !_vm.isTAComplete,
                                loading: _vm.isLoading()
                              },
                              on: {
                                click: function($event) {
                                  return _vm.validateSubmitSchedule()
                                }
                              }
                            },
                            [
                              _vm._v("\n            Done"),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v(
                                  "\n              mdi-check-outline\n            "
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.submitMenu,
                    callback: function($$v) {
                      _vm.submitMenu = $$v
                    },
                    expression: "submitMenu"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-text", { staticClass: "pa-5" }, [
                        _c("span", [_vm._v(_vm._s(_vm.submitText))])
                      ]),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c("v-btn", { attrs: { color: "tertiary" } }, [
                            _vm._v("\n              Close\n            ")
                          ]),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "success" },
                              on: {
                                click: function($event) {
                                  return _vm.finalSubmitSchedule()
                                }
                              }
                            },
                            [_vm._v("\n              Yes\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", "max-width": "1400" },
          model: {
            value: _vm.htmlDialog,
            callback: function($$v) {
              _vm.htmlDialog = $$v
            },
            expression: "htmlDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "pa-5", staticStyle: { "max-height": "75vh" } },
                [
                  _c("MailingPreview", {
                    attrs: {
                      "split-id": _vm.splitIdDialog,
                      "mailing-item-id": _vm.mailingItemIdDialog
                    }
                  })
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "tertiary" },
                      on: {
                        click: function($event) {
                          return _vm.closeHtmlDialog()
                        }
                      }
                    },
                    [_vm._v("\n          Close\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }