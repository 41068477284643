export const atLeastOneDigit = (value) => /(?=.*\d)/.test(value)
export const atLeastOneUppercase = (value) => /(?=.*[A-Z])/.test(value)
export const atLeastOneLowercase = (value) => /(?=.*[a-z])/.test(value)
export const atLeastOneSpecial = (value) => /(?=.*[-+_!@#$%^&*.,?])/.test(value)
export const atLeastOneArrayElement = (value) => value !== undefined && value.length !== 0
export const noPeriods = (value) => !value.includes('.')
export const noSpaces = (value) => !value.includes(' ')
export const noSpecialCharacters = (value) => /^[a-zA-Z0-9 _-]*$/.test(value)
export const fromAddress = (value) => /^[A-Za-z0-9-_.]+$/.test(value)
export const dateSlashMMDDYYYY = (value) => /^((0|1)\d{1})\/((0|1|2)\d{1})\/((19|20)\d{2})/.test(value)
export const dateDashYYYYMMDD = (value) => /^((19|20)\d{2})-((0|1)\d{1})-((0|1|2)\d{1})/.test(value)
